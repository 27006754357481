import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
// import LinearProgressWithLabel from "../../components/linearProgreswithLabel";
import { useSelector } from 'react-redux';
import AccountSetupModal from './../../components/modals/accountSetup';
import PropertyCard from "../../components/propertyCard";
import Tabs from "../../components/tabs/tabs";
import { checkLogin } from "../../utils/functions";
import store from "../../redux/store";

import ProjectCard from "../../components/projectCard";
import CustomSlider from "../../components/customSlider";
import CustomIcon from "../../components/customIcon";
import MobileBottomTab from "../../components/tabs/mobileBottomTab";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import listingService from "../../services/listingServices";
import { PropertyTable } from "../../components/tables";

export default function Dashboard(){

    const userState = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();

    const [properties,setProperties] = useState([]);

    const [url,setUrl] = useState("");

    const navigate = useNavigate();

    const user = useSelector(state=>state.user.data);

    useEffect(()=>{
        console.log(user,"user");
    },[user])

    const [width,setWidth] = useState(window.innerWidth);

    const [currentStep,setCurrentStep] = useState(0);
    const [showSignUp,setShowSignUp] = useState(false);

    useEffect(()=>{
        if(searchParams.get('show')){
            searchParams.delete('show');
            setSearchParams(searchParams);
        }
        if(user?.status === "FIRST_TIME") setCurrentStep(0);
        if(user?.status === "REGISTERED") setCurrentStep(1);
        if(user?.status === "KYC_COMPLETE" || user?.status === "REJECTED") setCurrentStep(2);
        if(user?.status === "KYC_APPROVED") setCurrentStep(3);
        if(user?.status === "APPROVED") setCurrentStep(4);
        if(userState.state === "FIRST_TIME" || userState.state === "REGISTERED" || userState.state === "KYC_APPROVED") setShowSignUp(true);
        if(user?.status === "APPROVED")
        {
            let redirectUrl = localStorage.getItem("redirectUrl")
            localStorage.removeItem(redirectUrl)
            if(redirectUrl)
            {
                navigate(redirectUrl)
            }
        }
    // eslint-disable-next-line
    },[user?.status]);

    useEffect(()=>{
        window.addEventListener("resize",changeWidth);

        return ()=>window.removeEventListener("resize",changeWidth);
    },[]);

    useEffect(()=>{
        getListings();
    },[])

    const getListings = async()=>{
        let res = await listingService.getApprovedListing(user._id);
        console.log(res);
        if(res && res.success){
            if(user.membershiptype === "SELECT"){
                await getSelectListings(res.data, user._id);
            }else{
                setProperties(res.data);
                setUrl(res.url)
            }
        }
    }

    const getSelectListings = async(list, userId)=>{
        const res = await listingService.getApprovedSelectListing(userId);
        if(res && res.success){
            let propertyList = [
                ...res.data,...list
            ]
            setProperties(propertyList);
            setUrl(res.url)
        }
    }

    const changeWidth = () =>{
        setWidth(window.innerWidth); 
    }

    const tabs = [
        {
            name:"Properties",
            tab:<PropertiesTabPanel />
        },
        {   
            name:"Projects",
            tab:<ProjectTabPanel />
        },
        // {
        //     name:"Developers",
        //     tab:<DeveloperTabPanel />
        // },
    ];

    const [activeTab,setActiveTab] = useState(0);


    const handleTabClick = (index) =>{
        setActiveTab(index);
    }

    return(
        <Box pb={{xl:"0px",lg:"0px",md:"0px",xs:"77px"}}>
            <AccountSetupModal handleClose={()=>setShowSignUp(false)} open={showSignUp}  />
            <Grid container columnSpacing={3} rowSpacing={{md:3,xs:3}} mt={{md:0,xs:2}}>
                {
                    currentStep < 4 &&
                <Grid item xs={12} md={8}>
                    <DashBoardCard 
                    title={"Complete Account Setup"} 
                    subtitle={"In order to have complete access to the RealX platform, you'll have to complete your account"} 
                    body={
                        <Stack direction={"column"} spacing={{md:"20px",xs:1}} mt={{md:2,xs:1}}>
                            <ProfileSteps
                                title={"Create Account"}
                                subtitle={"You have successfully created your RealX account"}
                                done={true}
                            />

                            <ProfileSteps 
                                title={"Complete Profile Details"}   
                                subtitle={currentStep <= 0? "Sumbit your profile details to get started" :"You have successfully submitted all the profile details."}
                                done={currentStep > 0}
                                active={currentStep === 0}
                                
                                btnProps={{
                                    title:"Create Profile",
                                    onClick:()=>{
                                        setShowSignUp(true)
                                    }
                                }}
                                
                            />
                            <ProfileSteps 
                                title={"Complete KYC"}   
                                subtitle={currentStep > 2 ? "Your KYC verification has been completed." :currentStep <= 1?"Complete the KYC by Submitting required document details for verification.": user?.status === "REJECTED" ? "Your KYC verification has failed. Please Submit Document Details Again.":"Your details have been submitted for verification. This will be processed in 48 hours."}
                                active={currentStep === 1 || currentStep === 2}
                                done={currentStep > 2}
                                completeValue={currentStep === 1 ? 0 : 100}
                                btnProps={{
                                    title: user?.status === "REJECTED" ? "Resubmit Details" :"Submit Details",
                                    onClick:()=>{
                                        setShowSignUp(true)
                                    }
                                }}
                                otherAction={currentStep === 2 && user?.status === "KYC_COMPLETE" ?()=>{
                                    return(
                                        <Box
                                            sx={{
                                                borderRadius:"18px",
                                                bgcolor:"primary.light",
                                                padding:"12px 24px",
                                                display:"flex",
                                                justifyContent:"center",
                                                alignItems:"center",
                                                width:"fit-content",
                                                height:"fit-content"
                                                
                                            }}
                                        >
                                            <Typography variant="chipText" sx={{color:"primary.main"}}>Verfication Pending</Typography>
                                        </Box>
                                    )
                                }:null}
                            />
                            <ProfileSteps 
                                title={"Create Your Digital Assets Account"}   
                                subtitle={currentStep < 4? "You'll need to create a new digital assets account to receive the tokens." :"You have successfully submitted all the profile details."}
                                done={currentStep > 3}
                                active={currentStep === 3}
                                
                                btnProps={{
                                    title:"Create Account",
                                    onClick:()=>{
                                        setShowSignUp(true)
                                    }
                                }}
                                
                            />
                            {/* <ProfileSteps 
                                title={"Create Porfolio"}   
                                subtitle={"Start earning profits by purchasing your first piece of Real Estate"}
                                active={currentStep===3}
                                hideBar={true}
                                btnProps={{
                                    title:"Start Investing",
                                    onClick:()=>{
                                        navigate("/marketplace")
                                    }
                                }}
                            /> */}
                        </Stack>
                    }
                    />
                </Grid>
                // :
                // <Grid item xs={12} md={12}>
                // <DashBoardCard 
                //     title={"My Properties"} 
                //     icon={
                //         <Box sx={{
                //             width:{md:48,xs:32},height:{md:48,xs:32},
                //             bgcolor:"primary.light",
                //             borderRadius:"100%",
                //             display:"flex",
                //             justifyContent:"center",
                //             alignItems:"center"
                //         }}>
                //             <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:24,xs:16},height:{md:24,xs:16}}} />
                //         </Box>
                //     }
                //     body={
                //         <Box mt={2}>
                //         <PropertyTable 
                //             emptyState={
                //                 <Box
                //                 sx={{
                //                     flex:1,
                //                     width:"100%",
                //                     // bgcolor:"red"
                //                 }}
                //             >
                //                 <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                //                     <Box 
                //                         sx={{
                //                             width:{md:160,xs:88},height:{md:160,xs:88},
                //                             bgcolor:"primary.light",
                //                             borderRadius:"100%",
                //                             display:"flex",
                //                             justifyContent:"center",
                //                             alignItems:"center",
                //                             mt:{md:0,xs:3}
                //                         }}
                //                     >
                //                         <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:72,xs:32},height:{md:72,xs:32},}} />
                //                     </Box>
                //                     <Typography sx={{fontSize:"28px",fontWeight:500,mt:{md:4,xs:3},mb:{md:1.5,xs:0.5}}}>No Properties</Typography>
                //                     <Typography variant="subtitle1" sx={{fontSize:"16px",fontWeight:300,mb:{md:3,xs:2}}}>You've not made any investments yet.</Typography>
                //                     <Button onClick={()=>navigate("/marketplace")} sx={{mb:{md:0,xs:3}}} variant="contained">Explore Properties to invest</Button>
                //                 </Stack>
                //             </Box>
                //             }>
                //         </PropertyTable>
                //         </Box>
                //     }
                //     />
                // </Grid>
                }
                {/* {
                    currentStep < 4 ? 
                    <Grid item xs={12} md={4}>
                    <DashBoardCard 
                        title={"My Properties"} 
                        icon={
                            <Box sx={{
                                width:{md:48,xs:32},height:{md:48,xs:32},
                                bgcolor:"primary.light",
                                borderRadius:"100%",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center"
                            }}>
                                <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:24,xs:16},height:{md:24,xs:16}}} />
                            </Box>
                        }
                        body={
                            <PropertyTable 
                            emptyState={
                                <Box
                                sx={{
                                    flex:1,
                                    width:"100%",
                                    // bgcolor:"red"
                                }}
                            >
                                <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                    <Box 
                                        sx={{
                                            width:{md:160,xs:88},height:{md:160,xs:88},
                                            bgcolor:"primary.light",
                                            borderRadius:"100%",
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center",
                                            mt:{md:0,xs:3}
                                        }}
                                    >
                                        <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:72,xs:32},height:{md:72,xs:32},}} />
                                    </Box>
                                    <Typography sx={{fontSize:"28px",fontWeight:500,mt:{md:4,xs:3},mb:{md:1.5,xs:0.5}}}>No Properties</Typography>
                                    <Typography variant="subtitle1" sx={{fontSize:"16px",fontWeight:300,mb:{md:3,xs:2}}}>You've not made any investments yet.</Typography>
                                    <Button onClick={()=>navigate("/marketplace")} sx={{mb:{md:0,xs:3}}} variant="contained">Explore Properties to invest</Button>
                                </Stack>
                            </Box>
                            }>
                            </PropertyTable>
                            
                        }
                        />
                    </Grid>
                    :""
                } */}
                {
                    // currentStep < 3 && 
                    // <Grid item xs={12}>
                    //     <DashBoardCard 
                    //         title={"Recommended Properties"} 
                    //         subtitle={<span>Properties recommended for you</span>} 
                    //         icon={
                    //             <Box sx={{
                    //                 width:{md:48,xs:32},height:{md:48,xs:32},
                    //                 bgcolor:"primary.light",
                    //                 borderRadius:"100%",
                    //                 display:"flex",
                    //                 justifyContent:"center",
                    //                 alignItems:"center"
                    //             }}>
                    //                 <CustomIcon name="media-live" sx={{bgcolor:"primary.main",width:{md:24,xs:16},height:{md:24,xs:16}}} />
                    //             </Box>
                    //         }
                    //         toolbarBtn={()=>{
                    //             return(
                    //                 <Button onClick={()=>navigate("/marketplace")} variant="outlined">View all</Button>
                    //             )
                    //         }}
                    //         body={
                    //             <Box sx={{
                    //                 mt:5,
                    //                 position:"relative",
                    //                 marginX:"-40px",}}>
                    //                     {
                    //                         width > 900 ?
                    //                     <CustomSlider sx={{columnGap:2}}>
                    //                         {
                    //                             properties?.map((e,i)=>{
                    //                                 return(
                    //                                 <Grid item >
                    //                                     <PropertyCard data={properties[i]} url={url} />
                    //                                 </Grid>
                    //                                 )
                    //                             })
                    //                         }
                    //                     </CustomSlider>
                    //                     :
                    //                     <Grid sx={{px:5}} container rowSpacing={2.5} >
                    //                         {
                    //                             properties?.slice(0,4).map((e,i)=>{
                    //                                 return(
                    //                                 <Grid item xs={12} >
                    //                                     <PropertyCard data={properties[i]} url={url} />
                    //                                 </Grid>
                    //                                 )
                    //                             })
                    //                         }
                    //                     </Grid>
                    //                     }
                    //                 {/* <Grid container columnSpacing={2}
                    //                     sx={{
                    //                         overflow:"auto",
                    //                         flexWrap:"nowrap",
                    //                         px:5,
                    //                         "-ms-overflow-style":"none",
                    //                         scrollbarWidth:"none",
                    //                         "&::-webkit-scrollbar":{
                    //                             display: "none",
                    //                         },
                    //                         "&::-moz-scrollbar":{
                    //                             display: "none",
                    //                         },
                    //                     }}

                    //                 >
                    //                     {
                    //                         [...Array(10)].map((e)=>{
                    //                             return(
                    //                             <Grid item>
                    //                                 <PropertyCard />
                    //                             </Grid>
                    //                             )
                    //                         })
                    //                     }
                                        
                    //                 </Grid> */}
                    //             </Box>
                    //         }
                    //     />
                    // </Grid>
                }
                
            </Grid>
            <MobileBottomTab />

<Card sx={{mt:checkLogin()?{md:3,xs:2}:{md:15,xs:12},mx:checkLogin()?0:{xl:"100px",lg:"32px",md:"24px",xs:"16px"},mb:{xl:2,lg:3,md:2,xs:"77px"}}}>
<CardContent>
    <Stack spacing={"5px"}>
        <Stack direction={"row"} spacing={{md:3,xs:1.5}} alignItems={"center"} >
            <Box sx={{
                    width:{md:48,xs:32},height:{md:48,xs:32},
                    bgcolor:"primary.light",
                    borderRadius:"100%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}>
                {/* <CircleIcon sx={{width:24,height:24}} /> */}
                <CustomIcon name="media-live" sx={{bgcolor:"primary.main",width:{md:24,xs:16},height:{md:24,xs:16}}} />
            </Box>
            <Typography variant="mainCardTitle" sx={{flex:1}}>Properties Listings</Typography>
        </Stack>
    </Stack>
    <Stack direction={"row"} justifyContent={"space-between"}
        sx={{
            mt:{md:2,xs:0},
            py:1,
            pb:"10px",
            pl:{md:0,xs:0},
        }}
        
    >
        <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
        {/* <Stack direction={"row"} spacing={2}>
            <TextField variant="outlined" placeholder="Search" InputLabelProps={{ shrink: true }} />
            <Button variant="outlined">Advanced Filters</Button>
        </Stack> */}
    </Stack>
    <Divider sx={{
        width:"calc(100% + 80px)",
        mx:-5
    }} />
    <Grid container columnGap={2} rowSpacing={3} mt={{md:3,xs:1}} padding={2} sx={{backgroundColor: "#f7f7f7", paddingBottom: 5}}>
        {
            tabs[activeTab]?.tab
        }
    </Grid>
</CardContent>
{
    checkLogin()?
    <MobileBottomTab />
    :""
}
</Card>
        </Box>


    )
}



const PropertiesTabPanel = () =>{
    const [listings,setListings] = useState([]);
    const [url,setUrl] = useState("");
    
    useEffect(()=>{
        getListings();
    },[])

    const getListings = async()=>{
        const user = store.getState().user.data;
        let res = await listingService.getApprovedListing(user._id);
        // console.log(res);
        if(res && res.success){
            
            if(user.membershiptype === "SELECT"){
                getSelectListings(res.data, user._id);
            }else{
                setListings(res.data);
                setUrl(res.url)
            }

        }
    }


    const getSelectListings = async(list, userId)=>{
        const res = await listingService.getApprovedSelectListing(userId);
        if(res && res.success){
            let propertyList = [
                ...res.data,...list
            ]
            setListings(propertyList);
            setUrl(res.url)
        }
    }


    return (
        <Grid container  spacing={3}>
       { listings.map((e,i)=>{
            // console.log(e,"prop");
            return(
                <Grid item lg={4} md={6} sm={6} xs={12}
                sx={{ display:{md:'flex'}, justifyContent: 'center',  minWidth: '0'}}
                >
                    <PropertyCard data={e} url={url} />
                </Grid>
            )    
        })
       }
        </Grid>
        )
    
} 

const ProjectTabPanel = () =>{
    const [projects,setProjects] = useState([]);
    const [url,setUrl] = useState("");
    
    useEffect(()=>{
        getProjects();
    },[])

    const getProjects = async()=>{
        let res = await listingService.getApprovedProject();
        console.log(res);
        if(res && res.success){
            setProjects(res.data);
            setUrl(res.url)
        }
    }
    return (
        <Grid container spacing={3}>
        {projects.map((e,i)=>{
            return(
                <Grid item lg={4} md={6} sm={6} xs={12}
                sx={{ display:{md:'flex'}, justifyContent: 'center',  minWidth: '0'}}
                >
                    <ProjectCard data={e} url={url}/>
                </Grid>
            )  
        })}
        </Grid>
    
    )
    
} 

const DashBoardCard = ({title,subtitle,icon,body,toolbarBtn=()=>{}}) =>{
    return(
        <Card sx={{height:"100%"}}>
            <CardContent>
                <Stack direction={"column"} height={"100%"}>
                    <Stack spacing={"8px"}>
                        <Stack direction={"row"} spacing={{md:3,xs:1.5}} alignItems={"center"} >
                            {icon? icon :""}
                            <Typography variant="mainCardTitle" sx={{flex:1}}>{title}</Typography>
                            { toolbarBtn() }
                        </Stack>
                        {subtitle ? <Typography variant="subtitle1" sx={{fontSize:"18px",ml:icon?{md:"72px !important",xs:"44px !important"}:""}} >{subtitle}</Typography>:""}
                    </Stack>
                    {
                        body
                    }
                </Stack>
            </CardContent>
        </Card>
    );
}

const ProfileSteps = ({
    title,subtitle,active,done,
    hideBar=false,
    completeValue=0,
    btnProps={
        title:"Upload Documents",
        onClick:()=>{}
    },
    otherAction=null
    }) =>{
    return(
        <Box sx={{
            padding:{xs:2,md:2},
            borderRadius:"10px",
            border:"1px solid",
            borderColor:active ? "primary.main":"divider",
            
        }}>
            <Stack direction={{md:"row",xs:"column"}} spacing={{md:0,xs:2}} >
                <Stack width={{md:"70%",xs:"100%"}} direction={"column"}>
                    <Stack direction={"row"} spacing={{md:"20px",xs:"12px"}} >
                        <Box sx={{minWidth:{xs:16,md:32},height:{xs:16,md:32},bgcolor:active ? "success.light" :!done ? "background.default":"success.main",borderRadius:"100%",display:"flex",
                            justifyContent:"center",
                            alignItems:"center",}} >
                        {
                            active ?
                            <Box sx={{
                            width:{xs:8,md:16},
                            height:{xs:8,md:16},
                            bgcolor:"success.main",
                            borderRadius:"100%",
                            }}>
                            </Box>
                            :
                            !done ? "":
                            <CustomIcon name="check" sx={{bgcolor:"#FFF",width:{xs:8,md:16},height:{xs:8,md:16},}} />
                        }
                        </Box>
                        <Stack spacing={{md:0.5,xs:0.5}} >
                            <Typography sx={{fontSize:"22px",fontWeight:"500",lineHeight:1}}>{title}</Typography>
                            <Typography variant="subtitle1"  >{subtitle}</Typography>
                        </Stack>
                    </Stack>
                    {/* {   
                        !active ? "" :
                        !hideBar && 
                        <Box mt={{md:"24px",xs:"12px"}} ml={{xs:"24px",md:"55px"}}>
                            <LinearProgressWithLabel color="success" value={completeValue} />
                        </Box>
                    } */}
                </Stack>
                {
                    !active ? "" :
                    <Stack flex={1} direction={"row"}  justifyContent={{md:"flex-end"}}>
                        {otherAction ? otherAction() :
                        <Button sx={{ml:{xs:"24px",md:0}}} variant="contained" {...btnProps} >
                            {btnProps.title}
                        </Button>}
                    </Stack>
                }
            </Stack>
        </Box>
    )
}
