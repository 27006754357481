import store from "../redux/store";
import { server } from "../utils/server";



const getMyWatchlist = () =>{
    const user = store.getState().user.data;
    const data = {
        investorId:user._id
    }
    return server.post("/investor/features/getWatchlist",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const removeDLTAccount = (data) =>{
    return server.post("/investor/features/removeDLTAccount",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const updatePrimaryDLTAccount = (data) =>{
    return server.post("/investor/features/updatePrimaryDLTAccount",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const updateDLTCreationStatus = (data) =>{
    return server.post("/investor/features/updateDLTCreationStatus",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const toggleWatchlist = (id) =>{
    const user = store.getState().user.data;
    const data = {
        listingId:id,
        investorId:user._id
    }
    return server.post("/investor/features/toggleWatchlist",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const addInvite = (data) =>{
    return server.post("/invite/addInvite",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}


const getATransactionDetails = (investorId) =>{
    return server.post('/transactions/get/Byinvestor', {investorId: investorId})
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const createSelectUser = (data) => {
    const { investorId, amount, membershiptype } = data;

    const queryParams = new URLSearchParams({
        investorId,
        amount,
        membershiptype
      });

    // const queryParams = new URLSearchParams({
    //   emailId,
    //   membershiptype,
    //   mobileNumber,
    //   username,
    //   amount
    // });
  
    // const url = `/investor/auth/addnewselectinvestor`;
    const url = `/investor/auth/purchasemembership`;
    // const url = `/investor/auth/addnewselectinvestor?${queryParams.toString()}`;
  
    return server
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };


const userServices = {
    getMyWatchlist,
    toggleWatchlist,
    addInvite,
    createSelectUser,
    getATransactionDetails,
    removeDLTAccount,
    updatePrimaryDLTAccount,
    updateDLTCreationStatus
}

export default userServices;