import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkLogin } from "../../utils/functions";
import LoginModal from "../modals/login";

export default function MobileBottomTab(){
    const [activeTab,setActiveTab] = useState(0);
    const [showLogin,setShowLogin] = useState(false);
    const [showSignUp,setShowSignUp] = useState(false);
    const [showTerms,setShowTerms] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();


    const handleClick = (index) =>{
        setActiveTab(index);
        if(tabs[index]){
            navigate(tabs[index].link,{replace:true});
        }
    }
    const tabs = [
        {
            name:"Dashboard",
            link:"/dashboard"
        },
        // {
        //     name:"Marketplace",
        //     link:"/marketplace",
        // },
        {
            name:"Portfolio",
            link:"/portfolio"
        },
    ];

    useEffect(()=>{
        // console.log(tabs.findIndex((e)=>pathname.includes(e.link)),"----->Current tab");
        const index = tabs.findIndex((e)=>pathname.includes(e.link));
        if(index >= 0){
            setActiveTab(index);
        }
     // eslint-disable-next-line
    },[pathname]);

    return(
        <Box
            sx={{
                position:"fixed",
                display:{xs:"block",md:"none"},
                padding:2,
                bgcolor:"#FFF",
                bottom:0,
                left:0,
                width:"100vw",
                boxShadow:"#e1e1e1 0px 0px 5px;",
                zIndex:4
            }}
        >
            <LoginModal handleClose={()=>setShowLogin(false)} open={showLogin} openSign={showSignUp} setShowSignUp={setShowSignUp} showTerms={showTerms} setShowTerms={setShowTerms} />
            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
            {
                checkLogin() ? 
                tabs.map((e,i)=>{
                    return(
                        <Box 
                        onClick={()=>handleClick(i)}
                        sx={{
                            height:"fit-content",
                            width:"auto",
                            padding:"12px 24px",
                            bgcolor:activeTab === i ?"primary.light":"",
                            color:activeTab === i ?"primary.main":"",
                            borderRadius:"10px",
                            cursor:"pointer",
                            position:"relative",
                            "&::before":{
                                content:activeTab === i ?"''":'none',
                                position:"absolute",
                                width: "85%",
                                left: "50%",
                                height: "5px",
                                bgcolor: "primary.main",
                                borderRadius: "10px 10px 0px 0px",
                                transform: "translateX(-50%)",
                                bottom: "-16px",
                            }
                        }}>
                            <Typography variant="tabBtn" >{e.name}</Typography>
                        </Box>
                    );  
                })
                :
                <>
                    <Button onClick={()=>setShowLogin(true)} fullWidth variant="outlined" sx={{color:"#252525",borderColor:"#252525"}}>Login</Button>
                    <Button onClick={()=>{setShowLogin(true);setShowSignUp(true)}} fullWidth variant="contained" sx={{bgcolor:"#252525"}}>Signup</Button>
                </>
            }
        </Stack>
        </Box>
    )
}