import { Stack, TextField, Typography } from "@mui/material";
import React from "react";

export default function FormField({label,inputProps,labelVarient="label"}){
    return(
        <Stack direction={"column"} spacing={1}>
            <Typography variant={labelVarient}>{label}</Typography>
            <TextField {...inputProps} />
        </Stack>
    )
}