import React from "react";
import { Button, Dialog, Stack, Typography } from "@mui/material";

export default function AreYouSureModal({ open, setOpen, handleYes = () => { alert("submited") }, message, note, publicAddress, publicAddressMessage}) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <Stack spacing={2} padding={4}>
                <Stack>{message ? message : "Are you Sure?"}</Stack>
                {note && <Typography sx={{fontSize: "0.82rem !important"}}>Note: {note}</Typography>}
                {publicAddress && <Stack>
                    <Typography sx={{fontSize: "0.9rem !important"}}>{publicAddressMessage ? publicAddressMessage : "DLT Account Address"}: </Typography>
                    <Typography sx={{fontSize: "0.85rem !important", fontWeight: 500}}>{publicAddress}</Typography>
                </Stack>}
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Button sx={{ py: 1, px: 2 }} variant="contained" onClick={() => { handleYes(); handleClose() }}>Confirm</Button>
                    <Button sx={{ py: 0.75, px: 2 }} variant="outlined" onClick={handleClose}>Cancel</Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}