import { Box, Button, styled, Tooltip, tooltipClasses, Typography } from "@mui/material"
import CustomIcon from "./customIcon";
import React, {  useState } from "react";
import Divider from '@mui/material/Divider';

export const DividerComponent = () =>{
    return(
        <Box
            sx={{
                height:"1px",
                bgcolor:"#7B7B7B",
                width:"calc(100% + 80px)",
                marginLeft:"-40px"
            }}
        >

        </Box>
    )
}

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      maxWidth: 450,
    },
  }));


export const TableRow = (props) =>{
    return(
        <Box {...props}>
            <Typography variant="body2">{props.children}</Typography>
        </Box>
    );
}


export const TableRowImg = (elemProps) =>{
    const propertyName = elemProps.data.propertyInfo[0];
    const projectName = elemProps.data.propertyInfo[2]; // Assuming URL is defined elsewhere
    const imageURL = elemProps.data.propertyInfo[1]; // Direct URL without prefix assuming full URL is given
    const propertyCode = elemProps.data.propertyInfo[3];

    return(
        <Box {...elemProps} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={imageURL}
                    alt={propertyName}
                    style={{ width: '150px', maxHeight: '75px',borderRadius: '5px', objectFit: "cover",}} // Adjust margin as needed
                />
                {/* <Box>
                    <Typography variant="body1">{projectName}</Typography>
                    <Typography variant="body2">{propertyName}</Typography> 
                    <Typography variant="body2" sx={{ marginTop: '0px', color: "#ff2600"  }}>{propertyCode}</Typography> 
                </Box> */}
            </Box>
        </Box>
    );
}

export const TableRowMultilineInfo = (elemProps) =>{
    const propertyName = elemProps.data.propertyInfo[0];
    const projectName = elemProps.data.propertyInfo[2]; // Assuming URL is defined elsewhere
    const imageURL = elemProps.data.propertyInfo[1]; // Direct URL without prefix assuming full URL is given
    const propertyCode = elemProps.data.propertyInfo[3];

    return(
        <Box {...elemProps} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Typography variant="body2">{propertyName}</Typography> 
                    <Typography variant="body2" sx={{ marginTop: '0px', color: "#ff2600"  }}>{propertyCode}</Typography> 
                </Box>
            </Box>
        </Box>
    );
}

  export const StatusRow =(props) =>{

    const getColor = () =>{
        if(props.elemProps.statusMap && props.elemProps.statusMap[props.children]){
            return props.elemProps.statusMap[props.children].color;
        }
        else return "success"
    }
    return(
        <Box sx={{
            padding:"8px 24px",
            bgcolor:`${getColor()}.light`,
            color:`${getColor()}.main`,
            borderRadius:"18px",
            width:"fit-content"
        }}>
            <Typography variant="body2">{props.elemProps.statusMap && props.elemProps.statusMap[props.children] ? props.elemProps.statusMap[props.children].title : props.children}</Typography>
        </Box>
    )
}

export const DetailButton = (props) =>{
    return(
        <Button onClick={()=>props.elemProps.onClick(props.data)} style={{ padding: "12px 15px"}} variant="outlined">{props.elemProps.buttonTitle || "Show Details"}</Button>
    )
}


export const TxnRefNumber = (elemProps)=>{
    const TxnRefrNumber = elemProps.data.refNo;
    return(
        <Box sx={{display:"flex",justifyContent:"row",alignItems: "center"}}>
            <Typography variant="body2">{TxnRefrNumber.length > 4?`...${TxnRefrNumber.slice(-4)}`:TxnRefrNumber}</Typography>
            <TooltipComponent sx={{ml:2}} info={TxnRefrNumber}/>
        </Box>
    )
}

export const AmountPaidCommited=(elemProps)=>{
    const status=elemProps.data.propertyStatus;
    const CommitmentAmount=elemProps.data.amount;
    const amountToBePaid=elemProps.data.amtTobePaid;
   return(
   status === 'LIVE' ? (
        <Box display={'flex'} flexDirection={'row'}>
          <Typography sx={{pr:0.5}} variant="body2">{amountToBePaid}</Typography>
          <Divider sx={{ pt:2, transform: 'rotate(0deg)', bgcolor: 'black', width: '1%' }} />
         
          <Typography variant="body2" sx={{pl:0.5}}>{CommitmentAmount}</Typography>
        </Box>
      ) : (
        <Typography variant="body2">{amountToBePaid}</Typography>
      )
        
    )
  
}



const TooltipComponent = ({info}) =>{
    const [open,setOpen] = useState(false);
    return(
    <HtmlTooltip 
        open ={open}
        onClose={()=>setOpen(false)}
        onOpen={()=>setOpen(true)}
        leaveTouchDelay={5000}
        title={
            <Box
           
                sx={{
                    bgcolor:"#ff79711c",
                    borderRadius:1,
                    color:"#252525",
                    fontSize:"0.8rem",
                    padding:1,
                    fontWeight:400,
                    ml:2

                }}
            >
                {
                    info
                }
                {/* <Typography dangerouslySetInnerHTML={{__html: info}} variant="caption" color={"text.primary"}></Typography> */}
            </Box>
        }
    >
        <Box onClick={(e)=>{
            e.preventDefault();
            e.stopPropagation();
            setOpen(true)
        }}>
            <CustomIcon name="info" sx={{width:12,height:12,ml:1,bgcolor:'#f19708'}} />
        </Box>
    </HtmlTooltip>
    )
}

