import { Box, Button, Card, CardContent, Dialog, Stack, Typography ,Divider} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import transactionServices from "../../services/transactionServices";
import { DividerComponent } from "../miniComponents";
import CloseIcon  from '@mui/icons-material/Close';
import CustomIcon from "../customIcon";

export default function CompleteSecondPartPaymentModal({open,handleClose=()=>{},handleBankTransferSubmit,getTransactionDetails, currentTransaction}){

    const { enqueueSnackbar } = useSnackbar();

    // const copyText = (text) =>{
    //     navigator.clipboard.writeText(text);
    //     enqueueSnackbar("Copied to clipboard.",{variant:"success"})
    // }

    useEffect(()=>{
        console.log("abcd")
    },[])

    return(
        <Dialog
        scroll={"body"}
        PaperProps={{
            sx:{
                overflow:"auto",
                maxWidth:{md:"700px !important",xs:"100% !important"},
                margin:{xs:0},
                borderRadius:{xs:0,md:1},
                height:{xs:"100%",md:"auto"},
                width:{xs:"100%"}
            }}}
        open={open}
        onClose={handleClose}
            >
                <Card>
                    <CardContent sx={{paddingTop:{md:"20px"}}}>
                        <Box
                            sx={{
                                width:"100%",
                                top:0,
                                left:0,
                                bgcolor:"white",
                                position:{xs:"fixed",md:"relative"},
                                boxShadow:{xs:"0px -3px 10px #00000066",md:"none"},
                                padding:{xs:"16px 24px",md:0},
                                zIndex:4
                            }}
                        >
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="body4" sx={{fontWeight:500}}>Property Details</Typography>
                                <CloseIcon onClick={handleClose} sx={{width:24,height:24,color:"text.disabled",cursor:"pointer"}} />
                            </Stack>
                        </Box>
                        <Stack mt={2} spacing={4}>
                            <Box>
                                <DividerComponent />
                            </Box>
                            <Box>
                                <Typography variant="body1" sx={{fontWeight:500}}>Transfer Remaining ₹ {currentTransaction?.pendingAmt} to this bank account:</Typography>
                            </Box>
                            <AccInformation />
                            <Box sx={{height:{xs:40,md:"auto"}}}>
                            </Box>
                            <Box sx={{display:{xs:"none",md:"block"}}}>
                                <DividerComponent />
                            </Box>
                            <Box sx={{
                                width:"100%",
                                bottom:0,
                                left:0,
                                bgcolor:"white",
                                borderRadius:"15px 15px 0px 0px",
                                position:{xs:"fixed",md:"relative"},
                                boxShadow:{xs:"0px 3px 10px #00000066",md:"none"},
                                padding:{xs:"16px 24px",md:0},
                            }}>
                                <Box sx={{px:{xs:0,md:8}}}>
                                    <Button onClick={handleBankTransferSubmit} fullWidth variant="contained" color="secondary">Confirm</Button>
                                </Box>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
        </Dialog>
    )
}

const AccInformation = () =>{
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state=>state.user.data);

    const copyText = (text) =>{
        navigator.clipboard.writeText(text);
        enqueueSnackbar("Copied to clipboard.",{variant:"success"})
    }
    return(
        <Stack spacing={"10px"} sx={{mt: "10px !important"}}>
            {/* <Typography variant="body1" sx={{fontWeight:500}}>Transfer Money to this bank account:</Typography> */}
            <Box sx={{borderRadius:1,border:"1px solid #e1e1e1"}}>
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">A/c No: 10114176799</Typography>
                    <Box onClick={()=>copyText(`10114176799`)} sx={{cursor:"pointer",}}>
                        <CustomIcon name="copy"  />
                    </Box>
                </Stack>
                <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">Name: RealX Marketplace Pvt. Ltd.</Typography>
                    <Box onClick={()=>copyText("RealX Marketplace Pvt. Ltd.")} sx={{cursor:"pointer",}}>
                        <CustomIcon name="copy"  />
                    </Box>
                </Stack>
                <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">IFSC Code: 10114176799</Typography>
                    <Box onClick={()=>copyText("10114176799")} sx={{cursor:"pointer",}}>
                        <CustomIcon name="copy"  />
                    </Box>
                </Stack>
                <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">Bank: IDFC First Bank</Typography>
                    <Box onClick={()=>copyText("IDFC First Bank")} sx={{cursor:"pointer",}}>
                        <CustomIcon name="copy"  />
                    </Box>
                </Stack>
                 <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">Branch: Karve Nagar, Pune</Typography>
                    <Box onClick={()=>copyText("Karve Nagar, Pune")} sx={{cursor:"pointer",}}>
                        <CustomIcon name="copy"  />
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}