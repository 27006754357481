import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header";

export default function DashboardLayout(){
    return(
        <Box
        sx={{
            maxWidth:"1920px",
            position:"relative",
            margin:"auto"
        }}
        >
            <Header />
            <Box
                sx={{
                    padding:{xl:"100px",lg:"32px",md:"24px",xs:"16px"},
                    pt:{xl:"94px",lg:"94px",md:"94px",xs:"84px"},
                    maxWidth:"1920px"
                }}
            >
                <Outlet />
            </Box>
        </Box>
    )
}