
export default function AutoComplete(theme){
    return{
        MuiAutocomplete:{
            styleOverrides:{
                listbox:{
                },
                option:{
                    fontSize:14,
                },
                paper:{
                    marginTop:"4px",
                    border: "1px solid #E1E1E1",
                    boxShadow:"none !important",
                },
                popper:{

                }
            }
        }
    }
}