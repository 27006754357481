import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import {
    Link as RouterLink,
    useLocation,
  } from 'react-router-dom';
  import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import listingService from "../services/listingServices";

export default function CustomBreadcrumb({dark=false,id=""}){

    // const getTitle = async (propertyId) =>{
    //     const res = await listingService.getListingById(propertyId);
    //     if(res && res.success){
    //         const data = res.data;
    //         console.log(data);
    //         if(data?.propertyType === "Residential") return `${data?.basicInfo?.flatNo}, Wing-${data?.basicInfo?.wing} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
    //         if(data?.propertyType === "Commercial" || data?.propertyType === "Retail") return `${data?.basicInfo?.unitNo}, Wing-${data?.basicInfo?.wing} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
    //         if(data?.propertyType === "Land") return `Plot No. ${data?.basicInfo?.plotNo} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
    //         if(data?.propertyType === "Warehouse") return `Warehouse No. ${data?.basicInfo?.warehouseNo} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
    //         return "";
    //     }else return decodeURI(propertyId)
    // }

    const breadcrumbNameMap = {
        '/dashboard': 'Dashboard',
        '/dashboard/project': 'Project',
        '/dashboard/property': 'Properties',
        '/dashboard/developer': 'Developers',
      };

      const location = useLocation();
      const pathnames = location.pathname.split('/').filter((x) => x);
      
    return(
        <Breadcrumbs
        sx={{
            my:{md:"16px",xs:3}
        }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                return last ? (
                <Typography variant="body2" color={dark?"white":"text.primary"} sx={{textTransform:"capitalize"}} key={to}>
                    {breadcrumbNameMap[to] || decodeURI(value)}
                </Typography>
                ) : (
                    breadcrumbNameMap[to] ? 
                <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                    {breadcrumbNameMap[to]}
                </LinkRouter>
                :
                <LinkRouter underline="hover" color="inherit" to={".."} relative="path" key={to} state={{id:id}} >
                    {decodeURI(value)}
                </LinkRouter>
                );
        })}
        {/* <RouterLink re */}
        </Breadcrumbs>
    )
}

function LinkRouter(props) {
    return <Link variant="body2" sx={{fontSize:14,fontWeight:400,color:"#B0B0B0",textTransform:"capitalize"}} {...props} component={RouterLink} />;
  }