import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CustomIcon from "../customIcon";
import ModalComponent from "./modalComponent";

export default function KYCPending({open,handleClose}){
    return(
        <ModalComponent open={open} handleClose={handleClose}>
            <Stack spacing={2} alignItems={"center"} textAlign={"center"}>
                <Box 
                    sx={{
                        width:114,
                        height:114,
                        borderRadius:"100%",
                        bgcolor:"#FAF8EB",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",mb:2
                    }}
                >
                    <CustomIcon name="kyc-icon" sx={{
                        width:40,
                        height:"100%",
                        bgcolor:"#C1AD2E",
                    }} />
                </Box>
                <Typography variant="h5">KYC Verification Pending</Typography>
                <Typography variant="label">You can buy FRAX after KYC verification.<br/>KYC verification will be processed in 48 hours.</Typography>
            </Stack>
        </ModalComponent>
    )
}