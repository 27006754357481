import { server } from "../utils/server"


const getApprovedListing = (userId) =>{
    return server.get("/investor/listings/all", {
        params: {
            membertype: "REGULAR",
            userId
          }
    })
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const getApprovedSelectListing = (userId) =>{
    return server.get("/investor/listings/all", {
        params: {
            membertype: "SELECT",
            userId
          }
    })
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

// const getApprovedSelectListing = () =>{
//     return server.post('/investor/listings/allselect', {membertype: "SELECT"})
//     .then((res)=>{
//         return res.data;
//     })
//     .catch((err)=>{
//         console.log(err);
//     });
// }

const getApprovedProject = () =>{
    return server.get("/investor/projects/get")
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const getListingById = (id) =>{
    return server.get(`/investor/listings/${id}`)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const getProjectById = (id)=>{
    return server.get(`/projects/get/${id}`)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const getDeveloperById = (id)=>{
    return server.post("developerAdmin/auth/byId",{developerId:id})
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}


const listingService ={
    getApprovedListing,
    getApprovedSelectListing,
    getListingById,
    getProjectById,
    getDeveloperById,
    getApprovedProject,
}

export default listingService;