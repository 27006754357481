import { server } from "../utils/server"

const getDynamicAmount = (data) =>{
    return server.post('/salesAdmin/deals/getDynamicAmount', data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const distributionServices = {
    getDynamicAmount
}

export default distributionServices;