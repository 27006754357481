import { createSlice } from '@reduxjs/toolkit';

const initialState ={
    data:null,
    watchlist:[],
}


const userSlice = createSlice({
    name:"user",
    initialState:initialState,
    reducers:{
        saveUser:(state,action)=>{
            state.data = action.payload;
        },
        updateWatchlist:(state,action)=>{
            state.watchlist = action.payload;
        },
        clearUser:()=>initialState
    }
});

export const {saveUser,updateWatchlist,clearUser} = userSlice.actions;

export default userSlice.reducer;