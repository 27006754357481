import { server } from "../utils/server";

// import OtplessSdk from "otpless-js-sdk";
// const sdkIntance = new OtplessSdk(
//   {
//       appId: "OTPLess:MOAHFGEKOXAIPOFISDXXOOSGFAQFYLIT",
//       enableErrorLogging: true
//   }
// );


const sendOTP = (data) => {
    return server.post("investor/auth/login", data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const getUser = (data) => {
    return server.get("investor/auth/" + data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}


const verifyOTP = (data) => {
    return server.post("investor/auth/verifyLogin", data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const googleLogin = (data) => {
    return server.post("investor/auth/google/login", data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const acceptConditions = () => {
    return server.patch("investor/auth/acceptConditions")
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const savePersonalDetails = (data) => {
    return server.post("investor/auth/saveDetails", data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const updatePersonalDetails = (data) => {
    return server.put("investor/auth/updateDetails", data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const updateBankDetails = (data) => {
    return server.put("investor/auth/updateBankDetails", data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const completeKyc = (data) => {
    // const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return server.post("investor/auth/completeKyc", data)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const completeManualKyc = (data) =>{
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return server.post("investor/auth/completeManualKyc",data, config)
    .then(res=>{
        console.log(res.data);
        return res.data;
    })
    .catch(err=>{
        console.log(err);
        return null;
    })
}

const createBlockChainAcc = (data) =>{
    return server.post("investor/auth/createWallet",data)
    .then(res=>{
        console.log(res.data);
        return res.data;
    })
    .catch(err=>{
        console.log(err);
        return null;
    })
}

const submitMneomonic = (data) => {
    return server.post("realXAdmin/investor/optin", data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const encryptCredentials = (data) => {
    return server.post("algorand/auth/encrypt", data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const sendMnemonicThroughMail = (data) => {
    return server.post("/algorand/auth/credentialMail", data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const submitEncryptedText = (data) => {
    return server.post("algorand/auth/submit-mneomonic", data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const submitAadhaar = (data) => {
    return server.post("investor/auth/aadharinitiate", data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const verifyAadhaar = (data) => {
    return server.post("investor/auth/aadharotpverify", data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}



const authServices = {
    sendOTP,
    verifyOTP,
    googleLogin,
    acceptConditions,
    savePersonalDetails,
    updatePersonalDetails,
    updateBankDetails,
    completeKyc,
    completeManualKyc,
    createBlockChainAcc,
    getUser,
    submitMneomonic,
    encryptCredentials,
    submitEncryptedText,
    submitAadhaar,
    verifyAadhaar,
    sendMnemonicThroughMail
}

export default authServices;