import React, { useEffect, useState } from 'react'
import ModalComponent from './modalComponent'
import { Box, Button, Stack, Typography } from '@mui/material'
import Lottie from 'lottie-react';
import warning from "../../animations/warning.json"

 
export default function AlReadyOptedInMsg({open,handleClose}){
    const [animatingWar, setAnimatingWar] = useState(false);
    useEffect(() => {
        if (open) {
            setAnimatingWar(true);
        }
      }, [open]);
    
  return (
    <ModalComponent open={open} handleClose={handleClose}>
          <Box sx={{ textAlign: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: "24px 12px" }}>
              <Stack sx={{ alignItems: 'center', justifyContent: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: 1, minWidth: { md: '600px' }, mt: 2 }}>
                  {
                      animatingWar &&
                      <Box sx={{ width: 150, marginTop: "-35px" }}>
                          <Lottie animationData={warning} loop={false} />
                      </Box>

                  }
                     <Typography variant='landingSubTitle' sx={{ textAlign:'center'}}>You have already opted in <br></br> for this asset</Typography>
              </Stack>
          </Box>      
    </ModalComponent>
  )
}

