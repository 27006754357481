import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const CustomSlider = (props)=>{
    const sliderRef = useRef();
    const [activeSlide,setActiveSlide] = useState(0);
    const [state,setState] = useState({
        isScrolling: false,
        clientX: 0,
        scrollX: 0,
    })

    useEffect(()=>{
        if(props.myRef){
            props.myRef({
                scrollLeft:scrollLeft,
                scrollRight:scrollRight
            })
        }
    // eslint-disable-next-line
    },[props.myRef,state,props.children?.length])

    useEffect(()=>{
        window.addEventListener("mouseup",onMouseUp);
        return () => window.removeEventListener("mouseup",onMouseUp);
    // eslint-disable-next-line
    },[state])

    const onMouseDown = e => {
        setState({ ...state, isScrolling: true, 
         clientX: e.clientX });
        //  console.log("onMouseDown",e.clientX);
         sliderRef.current.style.cursor = 'grabbing';
      };
    
    const onMouseUp = () => {
        setState({ ...state, isScrolling: false });
        sliderRef.current.style.cursor = 'grab';
        // console.log("onMouseUp")
      };
    
    const onMouseMove = e => {
        const { clientX, scrollX } = state;
        // console.log(e);
        if (state.isScrolling) {
            sliderRef.current.scrollLeft = scrollX + clientX - e.clientX;
          setState({...state,scrollX:scrollX + clientX - e.clientX,clientX:e.clientX})
        //   console.log("onMouseMove",e.clientX,clientX, scrollX);
        }
      };

    
    const scrollLeft = (scroll=439) =>{
        sliderRef.current.scroll({
            behavior:"smooth",
            left:sliderRef.current.scrollLeft + scroll
        })
        if(activeSlide<props.children?.length - 1){
            setActiveSlide(activeSlide+1);
        }
        setState({...state,scrollX:sliderRef.current.scrollLeft + scroll})
    }

    const scrollRight = (scroll=439) =>{
        sliderRef.current.scroll({
            behavior:"smooth",
            left:sliderRef.current.scrollLeft - scroll
        })
        if(activeSlide>0){
            setActiveSlide(activeSlide-1);
        }
        setState({...state,scrollX:sliderRef.current.scrollLeft - scroll})
    }
    
    return(
        <Box>
            <Grid
                onMouseDown={onMouseDown}
                // onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                ref={sliderRef} container 
                sx={{
                    overflow:"auto",
                    flexWrap:"nowrap",
                    px:5,
                    "-ms-overflow-style":"none",
                    scrollbarWidth:"none",
                    "&::-webkit-scrollbar":{
                        display: "none",
                    },
                    "&::-moz-scrollbar":{
                        display: "none",
                    },
                    userSelect:"auto",
                    cursor:"grab",
                    ...props.sx
                }}
            >
                {
                    props.children
                }
            </Grid>
            {
                props.navigation?
                <Stack sx={{display:{md:"none",xs:"flex"}}} mt={1} direction={"row"} spacing={0.5} justifyContent={"center"}>
                    {
                        props.children?.length > 0  && [...Array(props.children?.length)].map((e,i)=>{
                            return(
                                <Box sx={{width:4,height:4,borderRadius:"100%",bgcolor:activeSlide===i?"primary.main":"divider"}}></Box>
                            )
                        })
                    }
                </Stack>
                :""
            }
        </Box>
    )
}

export default CustomSlider;