import ThemeProvider from './theme';
import { Provider } from "react-redux";
import Router from './routes';
import store from './redux/store';
import { SnackbarProvider } from "notistack";
import CustomSnackbar from './components/messageBox/customSnackbar';
import ScrollToTop from './components/scrollToTop';
import { server } from './utils/server';
import { authHeader } from './helpers/authHeader';
import CacheBuster from './CacheBuster';
import VesrionUpdate from './components/modals/versionUpdate';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
import initializeAnalytics from '../src/analytics';
initializeAnalytics();

const interCeptor = () => {

  server.interceptors.request.use((config) => {
    const token = authHeader();
    config.headers.Authorization = token;
    return config;
  }, null, { synchronous: true });
}

function App() {
  interCeptor()

  useEffect(() => {
    // Send a pageview hit
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <ThemeProvider>
      <GoogleOAuthProvider clientId="319102982878-s71f4e19hp1s0un042ibg5t098k4n87l.apps.googleusercontent.com">
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
              return (
                <VesrionUpdate open={true} />
              )
            }
            return (
              <Provider store={store}>
                <SnackbarProvider
                  anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom"
                  }}
                  autoHideDuration={1500}
                  Components={{
                    success1: CustomSnackbar
                  }} >
                  <ScrollToTop />
                  <Router />
                </SnackbarProvider>
              </Provider>
            )
          }}
        </CacheBuster>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
