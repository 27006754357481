import { Box, Button, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Tabs from "../../components/tabs/tabs";
import CustomIcon from "../../components/customIcon";
import MobileBottomTab from "../../components/tabs/mobileBottomTab";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PropertyTable, WatchListTable } from "../../components/tables";
import Grid from '@mui/material/Grid';
import { convertToIndianFormat } from "../../utils/functions";

export default function Portfolio(){
    const { watchlist } = useSelector(state=>state.user);
    const [childData, setChildData] = useState(null);

    // Function to receive data from child component
    const receiveDataFromChild = (data) => {
        // Calculate sum of investment amounts
        const totalInvestment = data.reduce((total, property) => {
            const investAmm = parseFloat(property.investAmm.replace(/,/g, ''));
            return total + investAmm;
        }, 0);

        const propertyCount = data.length;
        const tempData = {
            totalInvestment: totalInvestment,
            count: propertyCount
        }
      setChildData(tempData);
    };

    const tabs = [
        {
            name:"My Properties",
            tab:
            <Stack spacing={1}>
            { childData?.count  && 
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <CountCard icon={<img src="/assets/icons/property.png" alt="icon" />} count={childData?.count} label="Owned Property" />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <CountCard icon={<img src="/assets/icons/invest.png" alt="icon" />} count={convertToIndianFormat(childData?.totalInvestment)} label="Total Investment" />
                    </Grid>
                </Grid>
            }
            <PropertyTable sendDataToParent={receiveDataFromChild}/>
          </Stack>
        },
        {
            name:"My Watchlist",
            tab:<WatchListTable watchlist={watchlist} />
        },
        {
            name:"Canceled Investments",
            tab:<CanceledTable />
        },
    ];

    const [activeTab,setActiveTab] = useState(0);


    const handleTabClick = (index) =>{
        setActiveTab(index);
    }
    return(
        <Box pb={{xl:"0px",lg:"0px",md:"0px",xs:"77px"}}>
            {/* <Card sx={{mt:{md:5,xs:3}}}>
                <CardContent>
                    <Typography sx={{fontSize:"28px",fontWeight:"600",flex:1}}>Portfolio analysis</Typography>
                    <Stack direction={"row"} rowGap={1} columnGap={3} mt={3} mb={{md:5,xs:3}} flexWrap={"wrap"} >
                        {
                            data.map((e)=>{
                                return(
                                    <Box
                                        sx={{
                                            border:"1px solid #E1E1E1",
                                            borderRadius:1.5,
                                            padding:{md:4,xs:2}
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <Box
                                                sx={{
                                                    bgcolor:"primary.light",
                                                    padding:{md:2,xs:1.5},
                                                    borderRadius:1.5,
                                                    lineHeight:0,
                                                    height:"fit-content"
                                                }}
                                            >
                                                <CustomIcon name="media-live" sx={{bgcolor:"primary.main",width:{md:22,xs:16},height:{md:22,xs:16}}} />
                                            </Box>
                                            <Stack direction={"column"}>
                                                <Typography variant="caption">{e.title}</Typography>
                                                <Typography variant="h5">{e.value}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                );
                            })
                        }
                    </Stack>
                </CardContent>
            </Card> */}
            {/* {
                watchlist.length === 0?
                <EmptyState />
                : */}
                <Card sx={{mt:{md:5,xs:3}}}>
                <CardContent>
                    <Box mb={0}>
                        <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
                    </Box>
                    <Divider sx={{
                            width:"calc(100% + 80px)",
                            mx:-5
                        }} />
                    <Box mt={5}>
                        {
                            tabs[activeTab]?.tab
                        }
                    </Box>
                </CardContent>
                </Card>

            {/* } */}

            <MobileBottomTab />
        </Box>
    )
}

// const Chart = () =>{
//     return(
//         <Box
//             sx={{
//                 border:"1px solid #E1E1E1",
//                 width:"100%",
//                 height:470,
//                 borderRadius:1.5
//             }}
//         >

//         </Box>
//     )
// }


const CountCard = ({ icon, count, label }) => {
    return (
      <Card sx={{ backgroundColor: '#f5f5f5', borderRadius: 2, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', marginBottom: 2, padding: 0.5 }}>
        <CardContent sx={{ padding: '10px !important' }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              {icon}
            </Grid>
            <Grid item>
              <Typography variant="body1">{label}</Typography>
              <Typography variant="h3">{count}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };


const CanceledTable = ()=>{

    // const data = [
    //     {
    //         "transactionId":"4405652147",
    //         "propertyName":"45E, Wing-A",
    //         "investAmm":"5,00,000",
    //         "fraxReserved":"2",
    //         "investedDate":"04 July 2022",
    //         "ammRefund":"5,00,000",
    //         "refundStatus":"Completed",

    //     }
    // ]

    // const tableHeads = [
    //     {
    //         key:"transactionId",
    //         title:"Transaction ID"
    //     },
    //     {
    //         key:"propertyName",
    //         title:"Property Name"
    //     },
    //     {
    //         key:"investAmm",
    //         title:"Investment Amount"
    //     },
    //     {
    //         key:"fraxReserved",
    //         title:"Frax reserved"
    //     },
    //     {
    //         key:"investedDate",
    //         title:"Invested Date"
    //     },
    //     {
    //         key:"ammRefund",
    //         title:"Amount refunded"
    //     },
    //     {
    //         key:"refundStatus",
    //         title:"Refund Status"
    //     },
    //     {
    //         key:"",
    //         title:""
    //     },
    // ];


    // const DetailButton = (props) =>{
    //     return(
    //         <Button variant="outlined">Show Details</Button>
    //     )
    // }

    // const tableCells = [<TableRow />,<TableRow />,<TableRow />,<TableRow />,<TableRow />,<TableRow />,<StatusRow />,<DetailButton />];


    return (<EmptyTableState showAction={false} title={"No Cancelled Transactions"} subtitle={"You don't have any cancelled transactions yet."} />)
    // eslint-disable-next-line
    // return(
    //     <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={data} />
    // )
}

// const EmptyState = ({showAction=true}) =>{
//     const navigate = useNavigate();
//     return(
//         <Box
//         sx={{
//             flex:1,
//             width:"100%",
//             height:"calc(100vh - 200px)"
//             // bgcolor:"red"
//         }}
//     >
//         <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
//             <Box 
//                 sx={{
//                     width:{md:160,xs:88},height:{md:160,xs:88},
//                     bgcolor:"primary.light",
//                     borderRadius:"100%",
//                     display:"flex",
//                     justifyContent:"center",
//                     alignItems:"center",
//                     mt:{md:0,xs:3}
//                 }}
//             >
//                 <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:72,xs:32},height:{md:72,xs:32},}} />
//             </Box>
//             <Typography sx={{fontSize:"28px",fontWeight:500,mt:{md:4,xs:3},mb:{md:1.5,xs:0.5}}}>No Properties</Typography>
//             <Typography variant="subtitle1" sx={{fontSize:"16px",fontWeight:300,mb:{md:3,xs:2}}}>You don't own any real estate assets</Typography>
//             {showAction && <Button onClick={()=>navigate("/marketplace")} sx={{mb:{md:0,xs:3}}} variant="contained">Go to Marketplace</Button>}
//         </Stack>
//     </Box>
//     )
// }
const EmptyTableState = ({showAction=false,title,subtitle}) =>{
    const navigate = useNavigate();
    return(
        <Box
        sx={{
            flex:1,
            width:"100%",
            // bgcolor:"red"
        }}
    >
        <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
            <Box 
                sx={{
                    width:{md:160,xs:88},height:{md:160,xs:88},
                    bgcolor:"primary.light",
                    borderRadius:"100%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    mt:{md:0,xs:3}
                }}
            >
                <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:72,xs:32},height:{md:72,xs:32},}} />
            </Box>
            <Typography sx={{fontSize:"28px",fontWeight:500,mt:{md:4,xs:3},mb:{md:1.5,xs:0.5}}}>{title}</Typography>
            <Typography variant="subtitle1" sx={{fontSize:"16px",fontWeight:300,mb:{md:3,xs:2}}}>{subtitle}</Typography>
            {showAction && <Button onClick={()=>navigate("/dashboard")} sx={{mb:{md:0,xs:3}}} variant="contained">Explore Properties to invest</Button>}
        </Stack>
    </Box>
    )
}