import React, { useState,useEffect} from 'react'
import ModalComponent from "./modalComponent";
import { Box ,Stack, Typography,Button} from '@mui/material';
import Lottie from 'lottie-react';
import check from "../../animations/check.json"



export default function  SuccessOptedIn({open,handleClose=()=>{}}){
  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    if (open) {
      setAnimating(true);
    }
  }, [open]);

  return (   
    <ModalComponent open={open} handleClose={handleClose}>
       <Box sx={{textAlign:'center', bgcolor:'#fdedec',borderRadius:2,}}>
        <Stack rowGap={2} sx={{ padding: 3, alignItems: 'center', minWidth: { md: '600px' } }}>
          {
            animating &&
            <Box sx={{ width: 150, marginTop: "-35px" }}>
              <Lottie animationData={check} loop={false} />
            </Box>

          }
          <Typography variant='landingSubTitle' sx={{ textAlign: 'center' }}>You have successfully opted in <br></br> for this asset</Typography>
        </Stack>   
       </Box>
    </ModalComponent>
  )
}

