import { Divider, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { cloneElement, useEffect, useState } from "react";

export default function CustomTable({tableHeads=[],tableCells=[],data=[]}){

    const [page, setPage] = useState(1);
    const [count,setCount] = useState(0);

    useEffect(()=>{
        setCount(data.length);
    },[data])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onClick =(event, data) =>{
        event.stopPropagation();

        if(tableHeads[tableHeads.length - 1] && tableHeads[tableHeads.length - 1].onClick)
        {
            tableHeads[tableHeads.length - 1].onClick(data);
        }
        
    }

    return(
        <TableContainer >
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            tableHeads.map((e)=><TableCell style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap',}}>{e.title}</TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                {data.slice((page * 5) - 5, page * 5).map((e, index) => (
                    <TableRow key={index} sx={{ cursor: "pointer" }} onClick={() => onClick(e)}>
                        {tableCells.map((elem, i) => (
                            <TableCell key={i}>
                                {cloneElement(elem, { elemProps: tableHeads[i], data: e }, e[tableHeads[i].key])}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
            </Table>
            {
                count > 5 ?
                <>
                <Divider sx={{
                    width:"calc(100% + 40px)",
                    mx:-5,
                    mb:3,
                    mt:1.2    
                }} />
                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} >
                    <Typography variant="body2">{page * 5 - 4} - {(page * 5 <count)?page * 5 :count} of {count} results</Typography>
                    <Pagination count={Math.ceil(count/5)} page={page} onChange={handleChangePage} shape="rounded" siblingCount={0} boundaryCount={1}  />
                </Stack>
                </>
                :""
            }
        </TableContainer>
    )
}