import { Box } from "@mui/material";
import React from "react";

export default function CustomIcon({name="add",sx={}}){
    return(
        <Box
            // component={'img'}
            // src={`a`}
            sx={{
                width:16,
                height:16,
                bgcolor:"#252525",
                ...sx,
                mask:`url('/assets/icons/${name}.svg')  no-repeat center / contain`
            }}
        >
        </Box>
    )
}