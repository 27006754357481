import { Box, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";

export default function VesrionUpdate({open,handleClose=()=>{}}){
    return(
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
            sx: {
            //   maxHeight: "40%",
              height:"auto",
              borderRadius:"10px",
            //   alignSelf:"flex-end",
              position: "relative",
              overflow:"visible"
            }
          }}
          keepMounted
        >
            <DialogContent>
                <Stack direction={"column"} spacing={2}>
                    <Box component={"img"} sx={{width:"45%",margin:"auto"}} src={"/assets/images/logo.svg"}></Box>
                    <Typography sx={{textAlign:"center"}}>New Updates Available</Typography>
                    <Button onClick={()=>window.location.reload(true)} variant="contained">Update Now</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}