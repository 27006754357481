import {  FormControl, FormHelperText, MenuItem, Select, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useRef, useState } from "react";

export default function FormSelect({label,options=[],labelVarient="label",props={}}){
    const ref = useRef();
    const [ren,setRen] = useState(false);
    useEffect(()=>{
        if(ref.current && !ren){
            setRen(true);
        }
    },[ref,ren]) 
    return(
        <Stack direction={"column"} spacing={1}>
            <Typography variant={labelVarient}>{label}</Typography>
            <FormControl error={props.error} >
            
                <>
                    <Select
                    ref={ref}
                        //   value={age}
                        //   onChange={handleChange}
                        // disabled={ren}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            anchorEl:ref.current,
                        }}
                        {...props}
                        displayEmpty
                        IconComponent={()=><ExpandMoreIcon sx={{width:18,height:18,color:"text.disabled"}} />}
                        >
                        {
                            options.map((e)=>{
                            return <MenuItem value={e.value}><Typography variant="placeHolder">{e.title}</Typography></MenuItem>
                            })
                        }
                    </Select>
                    <FormHelperText>{props.helperText}</FormHelperText>
                    
                    </>
                </FormControl>
        </Stack>
        
    )
}