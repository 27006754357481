import { Box, FormControl, FormHelperText, Stack, Typography,CircularProgress } from "@mui/material";
import React,{ useState } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function FormFileField({label,inputProps={error:false,helperText:"",value:null,onChange:()=>{}}}){
    // const [file,setFile] = useState();
    const [loading,setLoading]=useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setLoading(true)
        // Do something with the files
        console.log(acceptedFiles);
        // setFile(acceptedFiles[0])
        inputProps.onChange(acceptedFiles[0])
        setLoading(false)
      }, [inputProps])
      const {getRootProps, getInputProps} = useDropzone({onDrop,maxFiles:1,})
    return(
        <Stack direction={"column"} spacing={1}>
            <Typography variant="label">{label}</Typography>
            {/* <TextField disabled {...inputProps} readOnly /> */}
            <FormControl error={inputProps.error} >
                <Box
                    sx={{
                        background:"#F8F8F8 0% 0% no-repeat padding-box",
                        border:"1px dashed",
                        borderColor:inputProps.error?"red":"#818E94",
                        borderRadius:1,
                        minWidth:290,
                        minHeight:"44.13px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        px:"24px",
                        cursor:'pointer',
                    }}
                    {...getRootProps()}>
                    <input {...getInputProps()} />
                    {loading ? (
                       
                            <CircularProgress size={30} sx={{ color: "red"}}  /> 
                       
                    ):(
                    <>
                    {
                        inputProps.value && inputProps.value.name ?
                        <Typography variant="placeHolder" sx={{fontSize:14,lineHeight:1.1}}>{inputProps.value.name}</Typography>
                        :
                        <Typography variant="placeHolder" sx={{color: "text.disabled",lineHeight:1.1}}>Choose file from storage</Typography>
                    }
                    </>
                    )}
                </Box>
                <FormHelperText>{inputProps.helperText}</FormHelperText>
            </FormControl>
        </Stack>
    )
}