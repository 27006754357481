import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function CustomTabs({props={},tabs=[],onClick=()=>{},activeTab,labelProps,tabProps={},disable=false}){

    const handleClick = (index) =>{
        onClick(index);
    }
    return(
        <Stack direction={"row"} {...props} >
            {
                tabs.map((e,i)=>{
                   return(
                    <Box 
                        onClick={()=>handleClick(i)}
                    sx={{
                        position:"relative",
                        cursor:"pointer"
                    }}>
                        <Typography color={activeTab !== i && disable ?"#B0B0B0":""} {...labelProps}>
                            {e.name}
                        </Typography>
                        {
                            activeTab === i ? 
                            <Box sx={{
                                position:"absolute",
                                width:"100%",
                                height:5,
                                bgcolor:"primary.main",
                                mt:3,
                                ...tabProps
                            }}>
                            </Box>
                            :""
                        }
                    </Box>
                   ) 
                })
            }
        </Stack>
    )
}