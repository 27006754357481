import { server } from "../utils/server"

const validateCoupon = (data) =>{
    return server.post('/coupon/auth/validate', data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const couponServices = {
    validateCoupon
}

export default couponServices;