import { Autocomplete, Box, Stack, TextField, Typography,Popper } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useRef } from "react";


const CustomPopper = (props) => {
    const { anchorEl, ...other } = props;
    return (
      <Popper {...other} anchorEl={anchorEl} style={{ width: anchorEl ? anchorEl.clientWidth : undefined }} />
    );
  };

export default function FormAutoCompleted({label,labelVarient="label",props={}}){
    const ref = useRef();
    return(
        <Stack  direction={"column"} spacing={1}>
            <Typography variant={labelVarient}>{label}</Typography>
                    <Autocomplete
                    
                        //   value={age}
                        //   onChange={handleChange}
                        renderInput={(params)=><TextField ref={ref} {...params} error={props.error} helperText={props.helperText} />}
                        ListboxProps={{

                        }}
                        componentsProps={{
                            popper:{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                anchorEl:ref.current,
                            }
                        }}
                        {...props}
                        PopperComponent={(popperProps) => (
                            <CustomPopper {...popperProps} anchorEl={ref.current} />
                          )}
                        popupIcon={<Box sx={{width:24,height:24}}><ExpandMoreIcon sx={{width:18,height:18,color:"text.disabled"}} /></Box>}
                        // IconComponent={()=>}
                        >
                    </Autocomplete>
        </Stack>
        
    )
}