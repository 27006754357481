import { Box } from "@mui/material";
import React from "react";

export default function CustomSnackbar(){
    return(
        <Box sx={{
            bgcolor:"success.light",
            padding:"14px 24px",
            minWidth:290
        }}>

        </Box>
    )
}