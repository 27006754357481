import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function Tabs({tabs=[],onClick=()=>{},activeTab}){
    // const [activeTab,setActiveTab] = useState(0);


    const handleClick = (index) =>{
        onClick(index);
    }

    return(
        <Stack direction={"row"}>
            {
                tabs.map((e,i)=>{
                    return(
                        <Box 
                        onClick={()=>handleClick(i)}
                        sx={{
                            height:"fit-content",
                            width:"auto",
                            padding:"12px 24px",
                            bgcolor:activeTab === i ?"primary.light":"",
                            color:activeTab === i ?"primary.main":"",
                            borderRadius:"10px",
                            cursor:"pointer",
                            position:"relative",
                            "&::before":{
                                content:activeTab === i ?"''":'none',
                                position:"absolute",
                                width: "85%",
                                left: "50%",
                                height: "5px",
                                bgcolor: "primary.main",
                                borderRadius: "10px 10px 0px 0px",
                                transform: "translateX(-50%)",
                                bottom: "-24px",
                            }
                        }}>
                            <Typography variant="tabBtn" >{e.name}</Typography>
                        </Box>
                    );  
                })
            }
        </Stack>
    )
}