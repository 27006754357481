import { Box, Stack, Typography } from "@mui/material";
// import CircleIcon from '@mui/icons-material/Circle';
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProjectCard({data, url}){
    console.log("Project data ", data);
    const navigate = useNavigate();

    const handleCardClick = () =>{
        navigate("/dashboard/project",{state:{id:data?._id}})
    }

    return(
        <Stack
            sx={{
               borderRadius:"10px",
               width:{md:398,xs:"auto"},
               height:{md:317,xs:190},
               cursor:"pointer"
            }}
            onClick={handleCardClick}
        >
            <Box
                sx={{
                    width:"100%",
                    height:"100%",
                    bgcolor:"purple",
                    borderRadius:"10px",
                    background:`url('${url}${data?.projectImages[0]?.docUrl}')`,
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    backgroundPosition:"center",
                    position:"relative"
                }}
            >
                <Stack 
                    direction={"column"}
                    justifyContent={"space-between"}
                    sx={{
                        position:"absolute",
                        width:"100%",
                        height:"100%",
                        background: "transparent linear-gradient(180deg, #0000001A 0%, #141E37CC 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "10px",
                        px:{md:4,xs:2},
                        pt:{md:4,xs:3},
                        pb:{md:5,xs:3},
                    }}
                >
                    <Stack direction={"row"} justifyContent={"space-between"}>
                    <Box 
                             sx={{
                                borderRadius:"18px",
                                padding:"8px 24px",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                width:"fit-content",
                                position:"relative",
                                background:"inherit",
                                overflow: "hidden",
                                "&::before":{
                                    content:"''",
                                    position: "absolute",
                                    width:"100%",
                                    height:"100%",
                                    borderRadius: "18px",
                                    background: "inherit",
                                    boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                                    filter: "blur(50px) brightness(12%)",
                                    // "-webkit-backdrop-filter": "blur(20px) brightness(88%)",
                                    zIndex:0
                                }
                            }}
                        >
                            <Typography variant="chipText" sx={{color:"#FFF",lineHeight:1,zIndex:1,position:"relative"}}>{data?.location?.city}</Typography>
                        </Box>
                        {/* <Box 
                            sx={{
                                borderRadius:"100%",
                                // background:"#ffffff33 0% 0% no-repeat padding-box",
                                width:36,
                                height:36,
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                padding:0.5,
                                position:"relative",
                                background:"inherit",
                                overflow: "hidden",
                                "&::before":{
                                    content:"''",
                                    position: "absolute",
                                    width:"100%",
                                    height:"100%",
                                    borderRadius: "18px",
                                    background: "inherit",
                                    boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                                    filter: "blur(50px) brightness(12%)",
                                    zIndex:0
                                }
                            }}
                        >
                            <CircleIcon sx={{width:"100%",height:"100%",color:"#FFF",zIndex:1,position:"relative"}} />
                        </Box> */}
                    </Stack>
                    <Stack direction={"column-reverse"} width={"80%"}>
                        {/* <Typography sx={{fontSize:14,fontWeight:400,color:"#B0B0B0"}}>Hiranandani</Typography> */}
                        <Typography sx={{fontSize:28,fontWeight:600,color:"#FFF"}}>{data?.projectName}</Typography>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}