import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function ImageGallery({images=[], isSelect, youtubeURL}){
    
    const [selectedImage,setSelectedImage] = useState(0);
    const [isSelectedVideo,setSelectedVideo] = useState(false);
    const isSelectProperty = isSelect === "SELECT" ? true: false;

    const goNext = () =>{
        if (selectedImage === images.length - 1) { 
            setSelectedImage(0);
        } else {
            setSelectedImage(selectedImage + 1);
        } 
    }

    const goPrev = () =>{
        if (selectedImage === 0) { 
            setSelectedImage(images.length - 1);
        } else {
            setSelectedImage(selectedImage - 1);
        }
    }

    return(
        <Grid container columnSpacing={{md:"20px",xs:1}} rowSpacing={{md:"20px",xs:"6px"}}>
            <Grid item xs ={12}>
                <Box
                    sx={{
                        width:"100%",
                        height:{md:420,xs:168},
                        borderRadius:"10px",
                        background:`url('${images[selectedImage]}')`,
                        backgroundSize:"cover",
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"center",
                        position:"relative"
                    }}
                >
                
            {    youtubeURL && selectedImage == images.length-1 && 
                <div className="youtube-video">
                    <iframe
                        width="100%"
                        height= "420"
                        src={`https://www.youtube.com/embed/${youtubeURL}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    />
                </div>
            }
                                        
<Typography
        variant="h6"
        sx={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            zIndex: '1',
            display: isSelectProperty ? 'block' : 'none',
            color: 'white',
            background: 'linear-gradient(125deg, transparent 25px, #FF382D 20px)',
            fontWeight: '400',
            padding: '5px 10px 5px 35px',
            fontSize: '1.025rem !important',
            textAlign: 'right'
        }}
      >
        Select Members Only
      </Typography>

                    <Box
                        sx={{
                            position:"absolute",
                            left:"0px",
                            top:"50%",
                            transform:"translateY(-50%)",
                            color:"#FFF",
                            padding:1,
                            bgcolor:"#0000006b",
                            lineHeight:1,
                            cursor:"pointer"
                        }} 
                        onClick={goPrev}
                    >
                        <ChevronLeftIcon fontSize="large" />
                        </Box>
                    <Box
                        sx={{
                            position:"absolute",
                            right:"0px",
                            top:"50%",
                            transform:"translateY(-50%)",
                            color:"#FFF",
                            padding:1,
                            bgcolor:"#0000006b",
                            lineHeight:1,
                            cursor:"pointer"
                        }} 
                        onClick={goNext}
                    >
                        <ChevronRightIcon fontSize="large" />
                        </Box>
                    
                </Box>
            </Grid>
            {
                images.map((e,i)=>{
                    if(i<6){
                        return(
                            <Grid  item xs ={2.4}>
                                <Box
                                    onClick={()=>setSelectedImage(i)}
                                    sx={{
                                        width:"100%",height:{md:100,xs:43},borderRadius:"10px",
                                        background:`url('${e}')`,
                                        backgroundSize:"cover",
                                        backgroundRepeat:"no-repeat",
                                        backgroundPosition:"center",
                                        cursor:"pointer",
                                        position:"relative",
                                        "&::before":{
                                            content:"''",
                                            position: "absolute",
                                            width:"100%",
                                            height:"100%",
                                            borderRadius: "10px",
                                            background: "#000",
                                            opacity:selectedImage === i ? 0 :0.6
                                        }
                                    }}
                                >   
                                {    youtubeURL && images.length-1 == i && 
                                <Grid 
                                sx={{
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    opacity: 0.5,
                                    height: '100%'
                                }}>
                                    <img 
                                    width="50%"
                                    height="50%"
                                        src="https://static.vecteezy.com/system/resources/previews/009/992/415/original/button-video-player-icon-sign-design-free-png.png"
                                    />
                                </Grid>
                                }
                                </Box>
    
                            </Grid>
                        )
                    }
                    else {

                        return "";
                    }


                })
            }
            {/* <Grid item xs ={2.4}>
                <Box
                    sx={{
                        width:"100%",height:{md:100,xs:43},borderRadius:"10px",
                        // background:"url('https://images.pexels.com/photos/261102/pexels-photo-261102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                        background:"url('/assets/images/listing/3.jpg')",
                        backgroundSize:"cover",
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"center",
                    }}
                >

                </Box>
            </Grid> */}
            {/* <Grid item xs ={2.4}>
                <Box
                    sx={{
                        width:"100%",height:{md:100,xs:43},borderRadius:"10px",
                        background:"url('https://images.pexels.com/photos/261102/pexels-photo-261102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                        backgroundSize:"cover",
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"center",
                    }}
                >

                </Box>
            </Grid>
            <Grid item xs ={2.4}>
                <Box
                    sx={{
                        width:"100%",height:{md:100,xs:43},borderRadius:"10px",
                        background:"url('https://images.pexels.com/photos/261102/pexels-photo-261102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                        backgroundSize:"cover",
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"center",
                    }}
                >

                </Box>
            </Grid> */}
            {/* <Grid item xs ={2.4}>
                <Box
                    sx={{
                        width:"100%",height:{md:100,xs:43},borderRadius:"10px",
                        background:"url('https://images.pexels.com/photos/260922/pexels-photo-260922.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                        backgroundSize:"cover",
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"center",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            borderRadius:"18px",
                            padding:"8px 24px",
                            display:{md:"flex",xs:"none"},
                            justifyContent:"center",
                            alignItems:"center",
                            width:"fit-content",
                            position:"relative",
                            background:"inherit",
                            overflow: "hidden",
                            "&::before":{
                                content:"''",
                                position: "absolute",
                                width:"100%",
                                height:"100%",
                                borderRadius: "18px",
                                background: "inherit",
                                boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                                filter: "blur(50px) brightness(12%)",
                                zIndex:0
                            }
                        }}
                    >
                        <Typography sx={{fontSize:14,fontWeight:400,color:"#FFF",lineHeight:1,position:"relative",zIndex:1}}>more photos</Typography>
                    </Box>
                </Box>
            </Grid> */}
        </Grid>
    )
}