import { Card, CardContent, Dialog, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

export default function ModalComponent(props){
    return(
        <Dialog
        
        maxWidth={"auto"}
        scroll={"body"}
        PaperProps={{sx:{overflow:"auto"}}}
        {...props}
        onClose={props.handleClose}
            >
            <Card>
                <CardContent>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography sx={props.titleStyle} variant="modalTitle">{props.title}</Typography>
                        <CloseIcon onClick={props.handleClose} sx={{width:24,height:24,color:"text.disabled",cursor:"pointer"}} />
                    </Stack>
                    {
                        props.children
                    }
                </CardContent>
            </Card>
        </Dialog>
    )
}