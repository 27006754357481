import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboardLayout";
import Dashboard from "./pages/dashboard/dashboard";
import LandingPage from "./pages/landing/landing";
import MarketPlace from "./pages/marketPlace/marketPlace";
import Portfolio from "./pages/portfolio/portfolio";
import ProjectDetails from './pages/marketPlace/project/projectDetails';
import PropertyDetails from './pages/marketPlace/property/propertyDetails';
import DeveloperDetails from "./pages/marketPlace/developer/developerDetails";
import MyProfile from "./pages/dashboard/profile/profile";
import AboutUsPage from "./pages/misc/aboutUs";
import MainLayout from "./layouts/mainLayout";
import FaqPage from "./pages/misc/faq";
import PolicyPage from "./pages/misc/policy";
import { checkLogin } from "./utils/functions";
import HowItWorks from "./pages/misc/howItWorks";
import WhyRealX from "./pages/misc/whyRealX";
import InvestPage from "./pages/marketPlace/property/investPage";
import AssetOptin from "./pages/landing/assetOptin";
import UrlShortner from "./helpers/urlShortner";
import Transactions from "./pages/transactions/transactions";

export default function Router() {
    return useRoutes([
      {
          path:'/',
          element:<MainLayout />,
          children:[
            {path:"",element:<LandingPage />},
            {path: "s/:propertyId", element: <UrlShortner />}
          ]
      },
      {
           path: "/optin/:id", element: <AssetOptin />
                 
      },
      {
            path:"lead/:distributorCode", element: <LandingPage />
      },
      {
        path:"lead/:distributorCode/:rm", element: <LandingPage />
      },
      { 
        path:"/dashboard",
        element:checkLogin()?<DashboardLayout />:<MainLayout />,
        children:[
          {path:"",element:checkLogin()?<Dashboard />:<Navigate to="/" replace />},
          {path:"profile",element:checkLogin()?<MyProfile />:<Navigate to="/" replace />},
          {path:"project",element:checkLogin()?<ProjectDetails />:<Navigate to="/" replace />},
          {path:"property",element:<Navigate to="/dashboard" />},
          {path:"property/:propertyId",element:<PropertyDetails />},
          {path:"property/:propertyId/invest",element:<InvestPage />},
          {path:"developer",element:checkLogin()?<DeveloperDetails />:<Navigate to='/' replace/>}
        ]
      },
      // {
      //   path:"/marketplace",<Navigate to="/" replace />
      //   element:checkLogin()?<DashboardLayout />:<MainLayout />,
      //   children:[
      //     {path:"",element: checkLogin() ? <MarketPlace /> :  },
      //     {path:"project",element: checkLogin() ? <ProjectDetails /> : <Navigate to="/" replace />},
      //     {path:"property",element:<Navigate to="/marketplace" replace />},
      //     {path:"property/:propertyId",element:<PropertyDetails />},
      //     {path:"property/:propertyId/invest",element:<InvestPage />},
      //     {path:"developer",element: checkLogin() ? <DeveloperDetails /> : <Navigate to="/" replace />}
      //   ]
      // },
      // {
      //   path:"/marketplace/property/invest",
      //   element:<InvestPage />
      // },
      {
        path:"/portfolio",
        element:checkLogin()?<DashboardLayout />:<Navigate to='/'/>,
        children:[
          {path:"",element:<Portfolio />},
        ]
      },
      {
        path:"/transactions",
        element:checkLogin()?<DashboardLayout />:<Navigate to='/'/>,
        children:[
          {path:"",element:<Transactions />},
        ]
      },
      
      { path: '*', element: <Navigate to="/" replace /> },
      // { path: '*', element: <Navigate to="/404" replace /> },
    ]);
  }