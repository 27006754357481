import { server } from "../utils/server"

const getLongUrl = (data) =>{
    return server.post('/urlShortner/auth/findLongUrl', data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const createShortUrl = (data) =>{
    return server.post('/urlShortner/auth/create', data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const urlShortnerServices = {
    getLongUrl,
    createShortUrl
}

export default urlShortnerServices;