

export default function Dialog(theme){
    return{
        MuiDialog:{
            styleOverrides:{
                paper: {
                    marginLeft:"24px",
                    marginRight:"24px",
                    
                    [theme.breakpoints.up('xs')]: {
                      width:"calc(100% - 48px)",
                    },
                    [theme.breakpoints.up('md')]: {
                        width:"auto",
                      },
                  },
            },

        }
    }
}