import axios from 'axios';

const getBankFromIFSCCode = (ifsc) =>{
    return axios.get(`https://ifsc.razorpay.com/${ifsc}`)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    })
}

const bankServices ={
    getBankFromIFSCCode
}

export default bankServices;