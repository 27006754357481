import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../components/customBreadcrumb";
import CustomTable from "../../../components/customTable";
// import CustomIcon from "../../../components/customIcon";
import { useLocation, useNavigate } from "react-router-dom";
import listingService from "../../../services/listingServices";
import { checkLogin, convertToIndianFormat, getTitle } from "../../../utils/functions";
import CustomIcon from "../../../components/customIcon";

export default function DeveloperDetails(){

    const { state } = useLocation();
    
    const [ developerData,setDeveloperData ] = useState();


    useEffect(()=>{
        getDeveloperData();
    // eslint-disable-next-line
    },[])

    const getDeveloperData = async() =>{
        let res = await listingService.getDeveloperById(state.id);
        if(res && res.success && res.data?.length >0){
            console.log(res.data[0]);
            setDeveloperData(res.data[0]);
        }
    }

    return(
        <Box sx={{mt:checkLogin()?0:{md:12,xs:8},mx:checkLogin()?0:{xl:"100px",lg:"32px",md:"24px",xs:"16px"},mb:checkLogin()?0:{xl:5,lg:5,md:5,xs:"77px"}}}>
            <Box
                sx={{
                    width:"100vw",
                    mx:{xl:"-100px",lg:"-32px",xs:"-16px"},
                    height:{md:442,xs:200},
                    background:"url('https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    backgroundPosition:"center",
                    position:"relative",
                }}
            >
                <Box
                    sx={{
                        width:"100%",
                        height:"100%",
                        position:"absolute",
                        background: "transparent linear-gradient(90deg, #000000 0%, #262626B8 47%, #54545400 100%) 0% 0% no-repeat padding-box",
                        px:{md:"100px",xs:3},
                        pb:{md:7,xs:3},
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"space-between"
                    }}
                >
                    <CustomBreadcrumb dark={true} />
                    <Stack direction={"column"} ml={{md:5,xs:1}}>
                        {/* <Typography variant="subtitle1" sx={{fontSize:12}} >Andheri east, Mumbai</Typography> */}
                        <Typography variant="h3" color={"#FFF"} >{developerData?.username}</Typography>
                        {
                            developerData?.website && 
                            <Stack onClick={()=>{window.open(developerData.website,"_blank")}} direction={"row"} mt={{md:2,xs:1}} spacing={1.5} sx={{cursor:"pointer",width:"fit-content"}} alignItems={"center"}>
                                <Typography variant="body2" color={"#FFF"} >Visit us</Typography>
                                <CustomIcon name="arrow-top-right-r" sx={{width:{md:16,xs:12},height:{md:16,xs:12},bgcolor:"#FFF"}} />
                            </Stack>
                        }
                    </Stack>
                </Box>
            </Box>
            <Grid container columnSpacing={3} rowGap={3} mt={5}>
                <Grid item md={4.5} xs={12}>
                    <DescriptionCard developerData={developerData} />
                </Grid>
                <Grid item md={7.5} xs={12}>
                    <PropertyListCard projectData={developerData} />
                </Grid>
            </Grid>
        </Box>
    )
}

const DescriptionCard = ({developerData})=>{
    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Description</Typography>
                <Stack mt={3} spacing={4}>
                    <Typography variant="body1">
                        At {developerData?.username}, our passion is to create landmarks that meet global standards, epitomise the values of our family, and are built on a legacy of trust spanning four decades. We are guided by our vision of ‘Building a Better Life’ and believe that homes transform lives. A home is a springboard for the dreams and aspirations, for living a healthier and fulfilled life.
                    </Typography>
                    <Typography variant="body1">
                        Every one of our developments delivers the highest level of design and craftsmanship, uncompromising quality, unparalleled service – putting {developerData?.username} developments in the league of the world’s finest.
                    </Typography>
                    {/* <Stack direction={"row"} columnGap={{md:3,xs:2}} rowGap={1} flexWrap={"wrap"}>
                        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                            <CustomIcon name="mail" sx={{width:16,height:16}} />
                            <Typography sx={{fontSize:16,fontWeight:400}} >test@mail.com</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                            <CustomIcon name="phone" sx={{width:16,height:16}} />
                            <Typography sx={{fontSize:16,fontWeight:400}} >1235457890</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                            <CustomIcon name="website" sx={{width:16,height:16}} />
                            <Typography sx={{fontSize:16,fontWeight:400}} >realx.in</Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={2}>
                        <Typography variant="h4" >Our socials</Typography>
                        <Stack direction={"row"} columnGap={{md:3,xs:2}} rowGap={1} flexWrap={"wrap"}>
                            <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                                <CustomIcon name="mail" sx={{width:16,height:16}} />
                                <Typography sx={{fontSize:16,fontWeight:400}} >test@mail.com</Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                                <CustomIcon name="phone" sx={{width:16,height:16}} />
                                <Typography sx={{fontSize:16,fontWeight:400}} >1235457890</Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                                <CustomIcon name="website" sx={{width:16,height:16}} />
                                <Typography sx={{fontSize:16,fontWeight:400}} >realx.in</Typography>
                            </Stack>
                        </Stack>
                    </Stack> */}
                </Stack>
            </CardContent>
        </Card>
    )
}

const PropertyListCard = ({projectData}) =>{

    const [data,setData] = useState([]);
    
    const navigate = useNavigate();

    useEffect(()=>{
        const temp = [];
        if(projectData && projectData.listings?.length>0){
            projectData.listings.map((e)=>{
                temp.push({
                    _id:e._id,
                    propertyName:getTitle(e),
                    propertyType:e.propertyType,
                    totalPurchasePrice:convertToIndianFormat(e.transactionDetails.totalCostOfPurchase),
                    totalInvestors:0,
                    filled:0
                });
                return null;
            });
        }
        console.log(temp)
        setData(temp)
    },[projectData])

    const tableHeads = [
        {
            key:"propertyName",
            title:"Property Name"
        },
        {
            key:"propertyType",
            title:"Property Type"
        },
        {
            key:"totalPurchasePrice",
            title:"Total Purchase Price"
        },
        {
            key:"_id",
            title:""
        },
    ];

    const TableRow = (props) =>{
        return(
            <Box {...props}>
                <Typography variant="body1">{props.children}</Typography>
            </Box>
        );
    }

    // const StatusChip = (props) =>{
    //     return(
    //         <Box sx={{
    //             borderRadius:"18px",
    //             padding:"8px 24px",
    //             display:"flex",
    //             justifyContent:"center",
    //             alignItems:"center",
    //             width:"fit-content",
    //             bgcolor:"primary.light",
    //             color:"primary.main"
    //         }}>
    //             <Typography variant="body2">2 Out 10 Sold</Typography>
    //         </Box>
    //     );
    // }

    const DetailButton = (props) =>{
        return(
            <Button onClick={()=>navigate("/dashboard/property",{state:{id:props.children}})} variant="outlined">Show Details</Button>
        )
    }

    const tableCells = [<TableRow />,<TableRow />,<TableRow />,<DetailButton />];
    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Listed Properties</Typography>
                <Box sx={{mt:3}}>
                    <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={data} />
                </Box>
            </CardContent>
        </Card>
    )
}