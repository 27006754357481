import { Box, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon  from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

export default function MainSideDrawer({open,handleClose=()=>{}}){

    const navigate = useNavigate();

    const handleClick =(link)=>{
        navigate(link);
        handleClose();
    }

    const tabs = [
        {
            name:"How it works",
            link:"/how-it-works"
        },
        {
            name:"Why RealX",
            link:"/why-realx",
        },
        {
            name:"FAQs",
            link:"/faq"
        },
        {
            name:"About Us",
            link:"/about-us"
        },
        // {
        //     name:"Contact Us",
        //     link:"/contact-us"
        // },
    ]

    return(
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box
                sx={{
                    width:"100vw",
                    bgcolor:"#FFF",
                    padding:3
                }}
            >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box  sx={{height:{md:54,xs:24},cursor:"pointer"}} component={"img"} src={"/assets/images/logo.png"}></Box>
                        <CloseIcon onClick={handleClose} sx={{width:24,height:24,color:"text.disabled",cursor:"pointer"}} />
                </Stack>
                <Stack mt={5} spacing={3}>
                    {
                        tabs.map((e)=>{
                            return(
                                <Box>
                                    <Typography  onClick={()=>handleClick(e.link)} variant='headerMenu' sx={{cursor:"pointer"}}>{e.name}</Typography>
                                </Box>
                            )
                        })
                    }
                    {/* <Box>
                        <Typography  onClick={()=>handleClick('/marketplace')} variant='headerMenu' sx={{cursor:"pointer"}}>Marketplace</Typography>
                    </Box> */}
                    {/* <Box>
                        <Typography variant='headerMenu' sx={{cursor:"pointer"}}>How To Invest?</Typography>
                    </Box>
                    <Box>
                        <Typography variant='headerMenu' sx={{cursor:"pointer"}}>Blog</Typography>
                    </Box>
                    <Box>
                        <Typography onClick={()=>handleClick('/about-us')} variant='headerMenu' sx={{cursor:"pointer"}}>About Us</Typography>
                    </Box> */}
                    {/* <Box>
                        <Typography variant='headerMenu' sx={{cursor:"pointer"}}>Terms & Conditions</Typography>
                    </Box> */}
                    {/* <Box>
                        <Typography onClick={()=>handleClick('policy')} variant='headerMenu' sx={{cursor:"pointer"}}>Privacy Policy</Typography>
                    </Box> */}
                </Stack>
            </Box>
        </Drawer>
    )
}