
export default function Table(theme){
    return{
        MuiTable:{
            styleOverrides:{
                root: {
                    
                  },
                
            }
        },
        MuiTableContainer:{
          styleOverrides:{
            root: {
                overflow:"auto"
              },
        }
        },
        MuiTableHead: {
            styleOverrides: {
              root: {
                background:"#F7F7F7",
                marginBottom:12
              },
              
            },
        },
        MuiTableBody: {
            styleOverrides: {
              root: {
                // paddingTop:12
              },
            },
        },
        MuiTableCell: {
            styleOverrides: {
              root: {
                padding:"16px 32px",
                whiteSpace:"nowrap",
                [theme.breakpoints.up('lg')]: {
                  padding: "16px 24px",
                },
                [theme.breakpoints.up('xs')]: {
                  padding: "16px 12px",
                },
              },
              body:{
                borderBottom:"none",
                "&:first-child":{
                    // padding:"24px 32px",
                }
              },
              head:{
                padding:"16px 32px",
                fontSize:14,
                fontWeight:400,
                color:"#B0B0B0",
                lineHeight:1.5    
              }
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
              root:{
                "&.Mui-selected":{
                    backgroundColor:theme.palette.primary.main,
                    color:"white",
                    '&:hover': {
                        backgroundColor: "#ff79711c",
                    }
                },
                '&:hover': {
                    backgroundColor: "#ff79711c",
                }
              },
              page: {
                // paddingTop:12,
                backgroundColor:theme.palette.primary.light,
                color:theme.palette.primary.main
              },
            },
        },
    }
}