// src/analytics.js
import ReactGA from 'react-ga';

const initializeAnalytics = () => {
  // Check if the environment is production
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize('G-GJTZWER08T');
  }
};

export default initializeAnalytics;