import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import urlShortnerServices from "../services/urlShortnerServices";
import { Helmet } from 'react-helmet';

export default function UrlShortner() {
    const { propertyId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const findLongUrl = async (data) => {
            try {
                const res = await urlShortnerServices.getLongUrl(data);
                console.log(res);
                if (res && res.success) {
                    navigate(res.data);
                } else {
                    navigate("/dashboard");
                }
            } catch (error) {
                console.error("Error fetching long URL:", error);
                navigate("/dashboard");
            }
        };

        findLongUrl({ propertyId });
    }, [propertyId, navigate]);

    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags for Thumbnail Preview */}
                <meta property="og:site_name" content="RealX"/>
                <meta property="og:title" content="RealX | Real Properties - Digital Holding"/>
                <meta property="og:description" content="Invest in high return properties. Starting as low as INR 5000."/>
                <meta property="og:image" content="https://platform.realx.in/assets/images/rx_preview.jpg"/>
                <meta property="og:type" content="website" />
                <meta property="og:image:type" content="image/jpg"/>
                {/* Additional Open Graph tags if needed */}
            </Helmet>
            <h1>URL Shortener</h1>
        </>
    )
}