import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Divider, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import CustomTable from "../../../components/customTable";
import CircleIcon from '@mui/icons-material/Circle';
import CustomBreadcrumb from "../../../components/customBreadcrumb";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CustomIcon from "../../../components/customIcon";
import { useLocation, useNavigate } from "react-router-dom";
import listingService from "../../../services/listingServices";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { checkLogin, convertToIndianFormat, getDocName, getTitle } from "../../../utils/functions";
import CustomSlider from "../../../components/customSlider";
import ImageGallery from "../../../components/imageGallery";

export default function ProjectDetails(){
    
    const { state } = useLocation();
    
    const [ projectData,setProjectData ] = useState();
    const [ url,setUrl ] = useState();


    useEffect(()=>{
        getProjectData();
    // eslint-disable-next-line
    },[])

    const getProjectData = async() =>{
        let res = await listingService.getProjectById(state.id);
        if(res && res.success && res.data?.length >0){
            console.log(res.data[0]);
            setProjectData(res.data[0]);
            setUrl(res.url)
        }
    }

    return(
        <Box sx={{mt:checkLogin()?0:{md:15,xs:12},mx:checkLogin()?0:{xl:"100px",lg:"32px",md:"24px",xs:"16px"},mb:checkLogin()?0:{xl:5,lg:5,md:5,xs:"77px"}}}>
            <CustomBreadcrumb />
            <MainCard projectData={projectData} url={url} />
            <Stack direction={"row"} mt={{md:5,xs:3}} columnGap={3} rowGap={3} flexWrap={"wrap"}>        
                <Stack sx={{width:{md:"calc(60% - 12px)",xs:"100%"}}} spacing={{md:5,xs:3}}>
                    <PropertyListCard projectData={projectData} />
                    {false && <AmenitiesCard />}
                    {projectData?.highlights?.length > 0 &&<HighlightCard projectData={projectData} />}
                    <LocationCard  projectData={projectData} />
                </Stack>
                <Stack sx={{width:{md:"calc(40% - 12px)",xs:"100%"}}} spacing={{md:5,xs:3}}>
                    {false && <StatisticsCard />}
                    <DocumentsCard projectData={projectData} url={url} />
                </Stack>
            </Stack>
        </Box>
    )
}

const MainCard = ({projectData,url}) =>{
    const [images,setImages] = useState([]);

    useEffect(()=>{
        if(projectData){
            setImages(projectData.projectImages.map((e)=>url+e.docUrl))
        }
        // eslint-disable-next-line
    },[projectData])
    return(
        <Card>
            <CardContent>
                <Typography variant="subtitle1" sx={{fontSize:12}} >{projectData?.location?.city}</Typography>
                <Typography variant="h3" >{projectData?.projectName}</Typography>
                    <Grid container mt={3} rowGap={3} columnSpacing={5}>
                        <Grid item md={6} xs={12}>
                            <ImageGallery images={images} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={{md:4,xs:3}}>
                                {/* <Typography variant="body1" >There is an opportunity to purchase a 14,320 square feet commercial office space in L&T Seawoods Grand Central - India’s first and largest Transit Oriented Development - located in Navi Mumbai, for an all-in cost of Rs. 23,21,00,000 (Rs. 13,302 per sf purchase price).</Typography>
                                <Typography variant="body1" >Developed by L&T, the retail mall and one office tower has been bought by global PE fund Blackstone. Property Share is purchasing a premium sea facing office block on the 9th floor (total 11 floors) that is currently vacant but is being marketed to 3 other owners.</Typography> */}
                                    <Typography variant="body1" 
                                    // sx={{minHeight:{md:"312px",xs:"auto"}}} 
                                    dangerouslySetInnerHTML={{__html: projectData?.description}} />
                                <Grid container columnGap={{md:5,xs:3}} rowGap={{md:4,xs:1.5}}>
                                    <Grid item>
                                        <Typography variant="subtitle1" sx={{fontSize:12}} >No of Properties</Typography>
                                        <Typography variant="body1" sx={{fontWeight:500}} >Total {projectData?.listings?.length}</Typography>
                                    </Grid>
                                    {
                                        projectData?.propertyStage?.constructedWithOC ?
                                        <>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={{fontSize:12}} >Construction Type</Typography>
                                                <Typography variant="body1" sx={{fontWeight:500}} >Constructed With OC</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={{fontSize:12}} >Age Of Property</Typography>
                                                <Typography variant="body1" sx={{fontWeight:500}} >{projectData?.propertyStage?.ageOfProperty} years</Typography>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={{fontSize:12}} >Construction Type</Typography>
                                                <Typography variant="body1" sx={{fontWeight:500}} >Under Construction</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={{fontSize:12}} >% Completion</Typography>
                                                <Typography variant="body1" sx={{fontWeight:500}} >{projectData?.propertyStage?.completion}%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={{fontSize:12}} >Completion time</Typography>
                                                <Typography variant="body1" sx={{fontWeight:500}} >{projectData?.propertyStage?.timeInCompletion} years</Typography>
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                                <Box>
                                    <Typography variant="body1">Contact Us</Typography>
                                    <Stack direction={"row"} columnGap={{md:3,xs:2}} mt={2} rowGap={1} flexWrap={"wrap"}>
                                        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                                            <CustomIcon name="mail" sx={{width:16,height:16}} />
                                            <Typography variant="body3" >{projectData?.developer?.emailId}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                                            <CustomIcon name="phone" sx={{width:16,height:16}} />
                                            <Typography variant="body3" >{projectData?.developer?.mobileNumber}</Typography>
                                        </Stack>
                                        {/* <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                                            <CustomIcon name="website" sx={{width:16,height:16}} />
                                            <Typography variant="body3" >realx.in</Typography>
                                        </Stack> */}
                                    </Stack>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
            </CardContent>
        </Card>
    )
}

const PropertyListCard = ({projectData}) =>{

    const [data,setData] = useState([]);
    
    const navigate = useNavigate();

    useEffect(()=>{
        const temp = [];
        if(projectData && projectData.listings?.length>0){
            projectData.listings.map((e)=>{
                temp.push({
                    _id:e._id,
                    propertyName:getTitle(e),
                    propertyType:e.propertyType,
                    totalPurchasePrice:convertToIndianFormat(e.transactionDetails?.totalCostOfPurchase),
                    totalInvestors:0,
                    filled:0
                });
                return null;
            });
        }
        console.log(temp)
        setData(temp)
    },[projectData])

    const tableHeads = [
        {
            key:"propertyName",
            title:"Property Name"
        },
        {
            key:"propertyType",
            title:"Property Type"
        },
        {
            key:"totalPurchasePrice",
            title:"Total Purchase Price"
        },
        {
            key:"_id",
            title:""
        },
    ];

    const TableRow = (props) =>{
        return(
            <Box {...props}>
                <Typography variant="body1">{props.children}</Typography>
            </Box>
        );
    }

    // const StatusChip = (props) =>{
    //     return(
    //         <Box sx={{
    //             borderRadius:"18px",
    //             padding:"8px 24px",
    //             display:"flex",
    //             justifyContent:"center",
    //             alignItems:"center",
    //             width:"fit-content",
    //             bgcolor:"primary.light",
    //             color:"primary.main"
    //         }}>
    //             <Typography variant="body2">2 Out 10 Sold</Typography>
    //         </Box>
    //     );
    // }

    const DetailButton = (props) =>{
        return(
            <Button onClick={()=>navigate(`/dashboard/property/${props.children}`,{state:{id:props.children}})} variant="outlined">Show Details</Button>
        )
    }

    const tableCells = [<TableRow />,<TableRow />,<TableRow />,<DetailButton />];
    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Listed Properties</Typography>
                <Box sx={{mt:3}}>
                    <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={data} />
                </Box>
            </CardContent>
        </Card>
    )
}

const AmenitiesCard = () =>{
    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Amenities</Typography>
                <Grid container sx={{mt:1}} columnSpacing={3} rowSpacing={2}>
                    <Grid item>
                        <Stack direction={"row"}
                        spacing={2}
                            sx={{
                                padding:"16px 32px",
                                backgroundColor:"background.default",
                                borderRadius:1,
                                alignItems:"center"
                            }}
                        >
                            <CircleIcon sx={{width:24,height:24}} />
                            <Typography variant="body2" >Swimming Pool</Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"}
                        spacing={2}
                            sx={{
                                padding:"16px 32px",
                                backgroundColor:"background.default",
                                borderRadius:1,
                                alignItems:"center"
                            }}
                        >
                            <CircleIcon sx={{width:24,height:24}} />
                            <Typography variant="body2" >Power Backup</Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"}
                        spacing={2}
                            sx={{
                                padding:"16px 32px",
                                backgroundColor:"background.default",
                                borderRadius:1,
                                alignItems:"center"
                            }}
                        >
                            <CircleIcon sx={{width:24,height:24}} />
                            <Typography variant="body2" >Jogging/Cycling Track</Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"}
                        spacing={2}
                            sx={{
                                padding:"16px 32px",
                                backgroundColor:"background.default",
                                borderRadius:1,
                                alignItems:"center"
                            }}
                        >
                            <CircleIcon sx={{width:24,height:24}} />
                            <Typography variant="body2" >Kids Play Area</Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"}
                        spacing={2}
                            sx={{
                                padding:"16px 32px",
                                backgroundColor:"background.default",
                                borderRadius:1,
                                alignItems:"center"
                            }}
                        >
                            <CircleIcon sx={{width:24,height:24}} />
                            <Typography variant="body2" >Gymnasium</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const LocationCard = ({projectData}) =>{
    const [location,setLocation] = useState({
        lat:null,
        lng:null
    })
    const libraries=["places"];
    const {isLoaded,loadError} = useLoadScript({
        googleMapsApiKey:"AIzaSyDYb-K3RBy_Fo3jWSySQOIIs5J0fGCIorU",
        libraries
    });

    useEffect(()=>{
        setLocation(projectData?.location?.coordinates);
    },[projectData]);

    useEffect(()=>{
        getLocation()
        // eslint-disable-next-line
    },[])

    function errorHandler(err) {
        if (err.code === 1) {
            console.log("Error: Access is denied!");
            // setAccessed(false)
        } else if (err.code === 2) {
            console.log("Error: Position is unavailable!");
        }
    }

    function getLocation() {
        if (navigator?.geolocation) {
            // timeout at 60000 milliseconds (60 seconds)
            var options = {
                timeout: 60000
            };
            navigator.geolocation.getCurrentPosition(()=>{}, errorHandler, options);
        } else {
            console.log("Sorry, browser does not support geolocation!");
        }
    }

    if(loadError) console.log(loadError);
    if(!isLoaded) return "Loading Maps";

    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Location</Typography>
                <Box width={"100%"} height={"270px"} mt={3}>
                    <GoogleMap 
                        clickableIcons={false}
                        streetView={false}
                        options={{
                            fullscreenControl: false,
                            streetViewControl:false,
                            mapTypeControl:false,
                        }}
                        // onLoad={(map)=>setMap(map)}
                        mapContainerStyle={{height:"100%",width:"100%"}} zoom={15} center={location}
                       >
                            {/* <Marker key={0} position={location}/> */}
                            <Marker key={1} position={location}/>
                    </GoogleMap>
                </Box>
            </CardContent>
        </Card>
    )
}

const StatisticsCard = () =>{
    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Statistics</Typography>
                <Divider sx={{
                        width:"calc(100% + 80px)",
                        mx:-5,
                        my:3    
                    }} />
                <Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="subtitle1" sx={{fontSize:18}} >Target</Typography>
                        <Stack direction={"row"}>
                            <Typography variant="subtitle1" sx={{fontSize:18,color:"primary.main"}} >10</Typography>
                            <Typography variant="subtitle1" sx={{fontSize:18}} >&nbsp;/ 20</Typography>
                        </Stack>
                    </Stack>
                    <LinearProgress variant="determinate" value={30} sx={{mt:2}} />
                    <Typography variant="subtitle1" sx={{fontSize:14,alignSelf:"flex-end",mt:1.2}} >25 % Properties Filled</Typography>
                </Stack>
                <Stack direction={"column"} spacing={3} mt={3}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body2" >Min. Dividends Paid from Rental Income</Typography>
                        <Stack direction={"row"}>
                            <Typography sx={{fontSize:18,fontWeight:500}} >₹ 20,000</Typography>
                            <Typography variant="subtitle1" sx={{fontSize:18}} >&nbsp;/ month</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body2" >Avg. Property Appreciation</Typography>
                        <Stack direction={"row"}>
                            <Typography sx={{fontSize:18,fontWeight:500}} >₹ 20,00,000</Typography>
                            <Typography variant="subtitle1" sx={{fontSize:18}} >&nbsp;/ year</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body2" >Expiry Date</Typography>
                        <Typography sx={{fontSize:18,fontWeight:500}} >26th June 2023</Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

const DocumentsCard = ({projectData,url}) =>{
    const [documents,setDocumnets] = useState([]);
    useEffect(()=>{
        if(projectData){
            // console.log(propertyData.documents)
            setDocumnets(projectData.documents)
        }
    },[projectData]);
    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Documents</Typography>
                <Stack mt={2.5} direction={"column"} spacing={2}>
                    {
                        documents.map((doc)=>{
                            return(
                                <Stack direction={"row"}
                                    spacing={2}
                                    sx={{
                                        padding:"20px 32px",
                                        backgroundColor:"background.default",
                                        borderRadius:1,
                                        alignItems:"center"
                                    }}
                                >
                                    <CustomIcon name="file" sx={{width:20,height:20}} />
                                    <Typography variant="body2" flex={1} >{getDocName(doc.docName)}</Typography>
                                    <Typography onClick={()=>{window.open(`${url}${doc.docUrl}`, '_blank');}} variant="body2" sx={{color:"primary.main",cursor:"pointer"}} >View Document</Typography>
                                </Stack>
                            )
                        })
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

const HighlightCard = ({projectData})=>{
    const [childRef,setChildRef] = useState({});

    const scrollLeft = () =>{
        if(document.body.clientWidth<900){
            childRef.scrollLeft(document.body.clientWidth - 56);
        }
        else{
            childRef.scrollLeft();
        }
    }

    const scrollRight = () =>{
        if(document.body.clientWidth<900){
            childRef.scrollRight(document.body.clientWidth - 56);
        }
        else{
            childRef.scrollRight();
        }
    }



    return(
        <Card>
            <CardContent>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Highlights</Typography>
                    <Stack direction={"row"} spacing={1.5}>
                        <Box onClick={scrollRight} sx={{bgcolor:"background.default",width:32,height:32,borderRadius:"100%",cursor:"pointer",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <ChevronLeftIcon fontSize="small" sx={{color:"divider"}} />
                        </Box>
                        <Box onClick={scrollLeft} sx={{bgcolor:"background.default",width:32,height:32,borderRadius:"100%",cursor:"pointer",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <ChevronRightIcon fontSize="small" sx={{color:"divider"}} />
                        </Box>
                    </Stack>
                </Stack>
                {/* <Stack mt={3} direction={"row"} spacing={3} flexWrap={"nowrap"} overflow={"auto"}> */}
                <CustomSlider navigation={true} myRef={setChildRef} sx={{columnGap:3,mt:3,px:0}} >
                    {
                        projectData?.highlights?.map((e,i)=>{
                            return(
                                <Grid item md={"auto"} xs={12} sx={{p:"1px"}}>
                                    <Box sx={{borderRadius:1.5,boxShadow:{md:"0px 0px 4px #00000014",xs:"none"},height:"100%",padding:{md:2,xs:0},maxWidth:{md:415,xs:"calc( 100vw - 80px)"},minWidth:{md:415,xs:"calc( 100vw - 80px)"}}}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Typography variant="h4" color={"primary.main"}>{(i+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}</Typography>
                                            <Typography dangerouslySetInnerHTML={{__html: e.highlightValue}} variant="body2"></Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </CustomSlider>
                {/* </Stack> */}
            </CardContent>
        </Card>
    )
}