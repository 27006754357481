import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
// import CloseIcon  from '@mui/icons-material/Close';
// import { DividerComponent } from "../miniComponents";
import ModalComponent from "./modalComponent";
import CustomIcon from "../customIcon";

export default function PaymentMode({open,handleClose=()=>{},onBankTransfer=()=>{},onPaymentGateway=()=>{}}){

    const [paymentMode,setPaymentMode] = useState(0);

    const checkSelected = (value) =>{
        return paymentMode === value
    }

    const handleSelect = (value) =>{
        setPaymentMode(value);
    }

    const handleSubmit = () =>{
        handleClose();
        if(paymentMode) onBankTransfer();
        else onPaymentGateway();
    }
    return(
        <ModalComponent  open={open} handleClose={handleClose} title={"Select Payment Method"}>
            <Stack mt={4} spacing={2.5}>
                <Stack onClick={()=>handleSelect(0)} direction={"row"} spacing={3} padding={2} sx={{cursor:"pointer"}}>
                    <Box sx={{minWidth:{xs:24,md:24},height:{xs:24,md:24},bgcolor:checkSelected(0)?"success.main":"success.light",borderRadius:"100%",display:"flex",
                            justifyContent:"center",
                            alignItems:"center",}} >
                                {
                                    checkSelected(0)?
                                    <CustomIcon name="check" sx={{bgcolor:"#FFF",width:{xs:12,md:12},height:{xs:12,md:12},}} />
                                    :""
                                }
                        </Box>
                    <Stack spacing={0.5}>
                        <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                            <Typography variant="body3" sx={{lineHeight:1}}>Payment Gateway</Typography>
                            {/* <CustomIcon name="info" sx={{bgcolor:"text.disabled",width:{xs:12,md:12},height:{xs:12,md:12},}} /> */}
                        </Stack>
                        <Typography variant="subtitle1">Instant. Best option for transactions below Rs. 80,000.</Typography>
                    </Stack>
                </Stack>
                <Stack onClick={()=>handleSelect(1)} direction={"row"} spacing={3} padding={2} sx={{cursor:"pointer"}}>
                    <Box sx={{minWidth:{xs:24,md:24},height:{xs:24,md:24},bgcolor:checkSelected(1)?"success.main":"success.light",borderRadius:"100%",display:"flex",
                            justifyContent:"center",
                            alignItems:"center",}} >
                            {
                                checkSelected(1)?
                                <CustomIcon name="check" sx={{bgcolor:"#FFF",width:{xs:12,md:12},height:{xs:12,md:12},}} />
                                :""
                            }
                        </Box>
                    <Stack spacing={0.5}>
                        <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                            <Typography variant="body3" sx={{lineHeight:1}}>Bank Transfer</Typography>
                            {/* <CustomIcon name="info" sx={{bgcolor:"text.disabled",width:{xs:12,md:12},height:{xs:12,md:12},}} /> */}
                        </Stack>
                        <Typography variant="subtitle1">Recommended for options above Rs. 80,000.</Typography>
                    </Stack>
                </Stack>
                <Box sx={{mt:1,px:{xs:8,md:10}}}>
                    <Button onClick={()=>{
                        handleSubmit(paymentMode)
                    }} fullWidth variant="contained">Proceed to payment</Button>
                </Box>
            </Stack>
        </ModalComponent>
        // <Dialog
        // scroll={"body"}
        // PaperProps={{sx:{overflow:"auto",maxWidth:470}}}
        // open={open}
        // onClose={handleClose}
        //     >
        //         <Card>
        //             <CardContent sx={{paddingTop:{md:"20px"}}}>
        //                 <Stack direction={"row"} justifyContent={"space-between"}>
        //                     <Typography variant="body3" sx={{fontWeight:500}}>Choose payment mode</Typography>
        //                     <CloseIcon onClick={handleClose} sx={{width:24,height:24,color:"text.disabled",cursor:"pointer"}} />
        //                 </Stack>
        //                 <Stack mt={1} spacing={2}>
        //                     <Box>
        //                         <DividerComponent />
        //                     </Box>
        //                     <Typography variant="body1" sx={{fontWeight:500}}>
        //                         Please select the convenient payment mode for completing the transaction.
        //                     </Typography>
        //                     <Box>
        //                         <DividerComponent />
        //                     </Box>
        //                     <Stack spacing={3} direction={"row"}>
        //                         <Button onClick={onPaymentGateway} fullWidth variant="contained">Payment Gateway</Button>
        //                         <Button onClick={onBankTransfer} fullWidth variant="outlined">Bank Transfer</Button>
        //                     </Stack>
        //                 </Stack>
        //             </CardContent>
        //         </Card>
        // </Dialog>
    )
}