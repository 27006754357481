import { Button,InputAdornment, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { saveUser, updateWatchlist } from "../../redux/reducers/userSlice";
import authServices from "../../services/authServices";
import userServices from "../../services/userServices";
import { loggout } from "../../utils/functions";
import FormField from "../form/formField";
import ModalComponent from "./modalComponent";
import TermsConditions from "./terms_conditions";
import '../../global.css';
import ReactGA from 'react-ga';

export default function LoginModal({open,organizationCode, handleClose =()=>{},openSign = false,setShowSignUp=()=>{},showTerms,setShowTerms=()=>{}}){

    const [otpForm,setOtpForm] = useState(false);
    const [signUp,setSignUp] = useState(false);
    const [number,setNumber] = useState("");
    const [inviteCode,setInviteCode] = useState("");
    const [otp,setOtp] = useState("");
    const user = useSelector(state=>state.user.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(openSign){
            setSignUp(openSign);
        }
    },[openSign]);

    const handleSendOtp = async() =>{
        ReactGA.event({
            category: "User Interaction",
            action: "Button Click",
            label: "Login Button"
          });
        const data ={
            mobileNumber: number,
            referralCode: signUp?inviteCode:null,
            distributorCode: organizationCode
        };
        const res = await authServices.sendOTP(data);
        if(res && res.success){
            setOtpForm(true);
            enqueueSnackbar("OTP sent successfully",{variant:"success"})
        }
        else{
            if(res.data.message){
                enqueueSnackbar(res.data.message,{variant:"error"})
            }
            else{
                enqueueSnackbar("Something went wrong!",{variant:"error"})
            }
        }
    }

    const handleVerifyOtp = async() =>{
        const data ={
            mobileNumber:parseInt(number),
            otp:otp
        }
        const res = await authServices.verifyOTP(data);
        if(res && res.success && res?.data?.length>0){
            sessionStorage.setItem("access",res.token.access.token);
            sessionStorage.setItem("refresh",res.token.refresh.token);
            dispatch(saveUser(res.data[0]));
            await getMyWatchlist()
            if(res.data[0].conditionAccepted){
                enqueueSnackbar("Logged in successfully",{variant:"success"})
                navigate(`/dashboard?show=${(res.data[0]?.status === "FIRST_TIME" || res.data[0]?.status === "REGISTERED" || res.data[0]?.status === "KYC_APPROVED")}`,{state:res.data[0]?.status});
            }
            else{
                setShowTerms(true);
                onClose();
            }
        }
        else{
            if(res.data.message){
                enqueueSnackbar("OTP is incorrect",{variant:"error"})
            }
            else{
                enqueueSnackbar("Something went wrong!",{variant:"error"})
            }
        }
    }

    const getMyWatchlist = async()=>{
        const res = await userServices.getMyWatchlist();
        if(res && res.success && res.data?.length>0){
            console.log("updating");
            dispatch(updateWatchlist(res.data));
        }
    }


    useEffect(()=>{
        if(!otpForm){
            setOtp("");
        }
    },[otpForm])

    useEffect(()=>{
        setNumber('')
        setInviteCode('')
        setOtp('')
    },[signUp])

    const onClose = () =>{
        setNumber("");
        // setOtp("");
        setOtpForm(false);
        handleClose();
        setTimeout(()=>{
            setSignUp(false);
            setShowSignUp(false);
        },200)
    }

    const onCloseTerms = (value) =>{
        console.log(value,"fjshfjk");
        setShowTerms(false);
        if(value){
            enqueueSnackbar("Logged in successfully",{variant:"success"})
            navigate(`/dashboard?show=${(user?.status === "FIRST_TIME" || user?.status === "REGISTERED" || user?.status === "KYC_APPROVED")}`,{state:user?.status});
        }
        else{
            enqueueSnackbar("You cannot proceed without accepting buyer agreement.",{variant:"error"})
            
            loggout();
        }
    }

    return(
        <>
            <TermsConditions open={showTerms} handleClose={onCloseTerms}  />
            <ModalComponent open={open} handleClose={onClose} title={otpForm?"Enter OTP":signUp?"Signup":"Login"} className="login-wrapper">
                {
                !otpForm ?
                signUp ?
                <SignupForm inviteCode={inviteCode} setInviteCode={setInviteCode} setSignUp={setSignUp} number={number} setNumber={setNumber} handleSendOtp={handleSendOtp} />
                :
                <LoginForm setSignUp={setSignUp} number={number} setNumber={setNumber} handleSendOtp={handleSendOtp} />
                :
                <OTPForm otp={otp} setOtp={setOtp} number={number} setOtpForm={setOtpForm} handleVerifyOtp={handleVerifyOtp} />
                }
            </ModalComponent>
        </>
    )
}


const LoginForm = ({number,setNumber,handleSendOtp,setSignUp}) =>{
    
    return(
        <>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter Mobile No *"} inputProps={{
                    placeholder:"",
                    // type:"number",
                    value:number,
                    onChange: (e) =>{
                        // console.log(RegExp('^[0-9]*$').test(e.target.value));
                        if( RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 11){
                            setNumber(e.target.value)
                        }
                    },
                    InputProps:{
                        startAdornment: <InputAdornment position="start">
                            <Typography variant="body2" sx={{color:"text.primary"}}>+91</Typography>
                            </InputAdornment>,
                        // endAdornment:<InputAdornment position="end">
                        //     <Button disabled={!(number?.length === 10)} variant="text">Send OTP</Button>
                        // </InputAdornment>
                        }
                }} />
                {/* <Stack direction={"row"} spacing={0.5} justifyContent={'center'}>
                    <Typography variant="body2">Have an invite code?</Typography>
                    <Typography onClick={()=>setSignUp(true)} variant="body2" sx={{color:"primary.main",cursor:"pointer"}}>Signup</Typography>
                </Stack> */}
            </Stack>
            <Button onClick={handleSendOtp} disabled={!(number?.length === 10)} variant="contained" sx={{mt:3}} fullWidth >Send OTP</Button>
        </>
    )
}

const SignupForm = ({number,setNumber,inviteCode,setInviteCode,handleSendOtp,setSignUp}) =>{
    
    return(
        <>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter Mobile No *"} inputProps={{
                    placeholder:"",
                    // type:"number",
                    value:number,
                    onChange: (e) =>{
                        // console.log(RegExp('^[0-9]*$').test(e.target.value));
                        if( RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 11){
                            setNumber(e.target.value)
                        }
                    },
                    InputProps:{
                        startAdornment: <InputAdornment position="start">
                            <Typography variant="body2" sx={{color:"text.primary"}}>+91</Typography>
                            </InputAdornment>,
                        // endAdornment:<InputAdornment position="end">
                        //     <Button disabled={!(number?.length === 10)} variant="text">Send OTP</Button>
                        // </InputAdornment>
                        }
                }} />
            </Stack>
            <Stack spacing={1.5} mt={2}>
                <FormField label={"Enter invite code *"} inputProps={{
                    placeholder:"",
                    // type:"number",
                    value:inviteCode,
                    onChange: (e) =>{
                        setInviteCode(e.target.value)
                    },
                   
                }} />
                <Stack direction={"row"} spacing={0.5}>
                    <Typography variant="body2">Already have an account?</Typography>
                    <Typography onClick={()=>setSignUp(false)} variant="body2" sx={{color:"primary.main",cursor:"pointer"}}>Login</Typography>
                </Stack>
            </Stack>
            <Button onClick={handleSendOtp} disabled={!(number?.length === 10 && inviteCode)} variant="contained" sx={{mt:4}} fullWidth >Send OTP</Button>
        </>
    )
}

const OTPForm = ({otp,setOtp,number,setOtpForm,handleVerifyOtp}) =>{
    
    return(
        <>
            <Stack direction={"column"} mt={3} justifyContent={"space-between"}>
                <Typography variant="body2" sx={{fontSize:12}}>OTP Sent To {number}</Typography>
                <Typography onClick={()=>setOtpForm(false)} variant="body2" sx={{fontSize:12,color:"primary.main",cursor:"pointer"}}>Change Number</Typography>
            </Stack>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter OTP *"} inputProps={{
                    placeholder:"_ _ _ _",
                    type:"number",
                    value:otp,
                    onChange: (e) =>{
                        // console.log(RegExp('^[0-9]*$').test(e.target.value));
                        if( RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 5){
                            setOtp(e.target.value)
                        }
                    },
                }} />
            </Stack>
            <Stack direction={"row"} mt={2} spacing={1}>
                <Typography variant="body2" >Haven't received OTP?</Typography>
                <Typography variant="body2" sx={{color:"primary.main",cursor:"pointer"}}>Resend OTP</Typography>
            </Stack>
            <Button onClick={handleVerifyOtp} disabled={!(otp?.length === 4)} variant="contained" sx={{mt:4}} fullWidth >Confirm and Next</Button>
        </>
    )
}