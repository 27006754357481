import { configureStore } from '@reduxjs/toolkit';
import userSlice from './reducers/userSlice';

const persistedState = sessionStorage.getItem('reduxState') 
                       ? JSON.parse(sessionStorage.getItem('reduxState'))
                       : {}

const store = configureStore({
    reducer:{
        user:userSlice,
    },
    preloadedState:persistedState
});


store.subscribe(()=>{
    // save a copy to sessionStorage
    sessionStorage.setItem('reduxState', JSON.stringify(store.getState()))
  })

export default store;